.main-container {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.main-container .sub-container {
	position: absolute;
	top: 50%;
	right: 10%;
	transform: translate(10%, -50%);
	width: 70rem;
	border-radius: 3.1rem;
	overflow: hidden;
	display: grid;
	background: white;
	box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);

	@media screen and (max-height: 700px) and (max-width: 1400px) and (min-width: 1100px) {
		height: 99%;
		overflow-y: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	@media screen and (max-height: 520px) {
		height: 100%;
		overflow-y: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.main-container .sub-container .header {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.main-container .sub-container .header .image {
	height: 100%;
	position: absolute;
	top: -1rem;
	right: -0.1rem;
}

.main-container .sub-container .header .image img {
	width: 100%;
	height: 100%;
}

.main-container .sub-container .header .text-container {
	padding: 4rem 6rem 1rem 6rem;
}

.main-container .sub-container .header .text-container .heading {
	font-weight: 400;
	font-size: 3.5rem;
	line-height: 5rem;
	color: #000;
}

.main-container .sub-container .header .text-container .text {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #282828;
	width: 27rem;
}

.main-container .sub-container .form {
	padding: 0 7.8rem 6rem 6.3rem;
	display: grid;
	grid-row-gap: 1rem;

	@media screen and (max-height: 555px) {
		padding: 0 7.8rem 0rem 6.3rem;
	}
}

.main-container .sub-container .form .input-container {
	display: grid;
	grid-row-gap: 1rem;
	position: relative;
}

.main-container .sub-container .form .input-container .input {
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 0.65rem;
}

.ArrowBtn {
	position: absolute;
	z-index: 5;
	top: 30%;
	// transform: translateY(-50%);
	right: -8rem;
	width: 11.1rem;
	height: 11.1rem;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0px 2.480964183807373px 12.404821395874023px 0px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5rem;
	font-weight: 100;
	text-decoration: none;
	color: #000;

	@media screen and (max-width: 900px) {
		right: -3rem;
	}
}

.passwordWrapper {
	display: flex;
	gap: 1.6rem;

	& > div {
		flex: 1;
	}
}

.main-container .sub-container .form .input-container .contact-info {
	display: flex;
	gap: 1.6rem;
}

.main-container .sub-container .form .input-container .contact-info .gender {
	flex: 1;
}

.main-container .sub-container .form .input-container .contact-info .dob {
	flex: 1;
}

.main-container .sub-container .form .input-container .input .label {
	font-weight: 400;
	font-size: 1.798rem;
	// line-height: 2.7rem;
}

.inputPass{
	// border: 1px solid #000;
	position: relative;
	
	input{
		width: 100%;
	}

	span{
		position: absolute;
		top: 50%;
		right: 1rem;
		transform: translateY(-50%);
		cursor: pointer;
	}
}

.main-container .sub-container .form .input-container .input .input-box {
	padding: 1.6rem;
	border: none;
	background-color: #eeeeee;
	outline: none;
}

.main-container .sub-container .form .input-container .forget-remember {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.main-container .sub-container .form .input-container .forget-remember .remember {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.8rem;
	line-height: 2.7rem;
}

.main-container .sub-container .form .input-container .forget-remember .remember .radio-button {
	width: 3rem;
	height: 3rem;
	margin-right: 1rem;
}

.main-container .sub-container .form .actions {
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-container .sub-container .form .actions .btn {
	background-color: #1943d8;
	color: #ffffff;
	font-weight: 500;
	width: 20rem;
	height: 6.2rem;
	border: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 1.798rem;
	line-height: 2.7rem;
	cursor: pointer;
	border-radius: 1rem;
}

.main-container .sub-container .form .actions .btn:disabled {
	background-color: #5a5e6a;
	cursor: default;
}
.main-container .sub-container .form .design {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 4rem;
}

.main-container .sub-container .form .design .line {
	width: 15rem;
	height: 0.1rem;
	background-color: black;
}

.main-container .sub-container .form .design .continue {
	font-weight: 500;
	font-size: 1.798rem;
	line-height: 2.7rem;
	color: #000;
}

.main-container .sub-container .form .footer {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.main-container .sub-container .form .footer button {
	background: transparent;
	border: none;
	cursor: pointer;
}
