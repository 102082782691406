.container {
    position: relative;
    overflow: hidden !important;
    width: 100%;
    height: 100vh;
}

.hover-animation {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(0%,-50%);
}