.WebcamCapture {
	z-index: 20;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;

	.Wrapper {
		display: flex;
		flex-direction: column;
		gap: 3rem;

		.ViewWrapper {
			width: 70rem;
			height: 52.5rem;

			video {
				width: 100%;
				height: 100%;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		.ButtonWrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 2rem;
            width: 100%;
            height: 20rem;

			button {
				cursor: pointer;
				width: 100%;
                height: 7rem;
				border-radius: 1.2rem;
				background: #dfecff;
				color: #054bff;
				font-family: "Poppins";
				font-size: 2.14rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
                border: none;
			}
		}
	}
}
