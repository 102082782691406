.Container {
	position: relative;

	.BackgroundImage {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		// height: 100%;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
			lightgray 0px -586.333px / 100% 210.393% no-repeat;
		filter: blur(5px);
		overflow: hidden;

		img {
			width: 100%;
			min-height: 100%;
			max-height: 250%;
		}
		video {
			width: 100%;
			min-height: 100%;
			max-height: 250%;
		}

		.Overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.3);
			filter: blur(5px);
		}
	}
}

.ViewPost {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	scrollbar-width: none;
	-ms-overflow-style: none;
	display: flex;
	padding: 1.3rem 2rem 1.3rem 1.3rem;
	gap: 1.5rem;

	&::-webkit-scrollbar {
		display: none;
	}

	.Left {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.StoryIndicatorBar {
			width: 70%;
			height: 0.5rem;
			position: absolute;
			top: 2rem;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			align-items: center;
			gap: 1rem;
			z-index: 3;

			.Bars {
				width: 100%;
				height: 100%;
				display: block;
				background: rgba(217, 217, 217, 0.4);
				border-radius: 2rem;
				position: relative;
				overflow: hidden;

				span {
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					background: #fff;
					transition: all 0.3s ease-in-out;

					&.on {
						width: 100%;
					}

					&.pause {
						width: 40%;
					}

					&.active {
						animation: fill 5s ease-in-out;
					}

					@keyframes fill {
						from {
							width: 0%;
						}
						to {
							width: 100%;
						}
					}
				}
			}
		}

		.ImageContainer {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			position: relative;
			z-index: 1;

			img {
				object-fit: contain;
				max-width: 100%;
				min-height: 62rem;
				max-height: 80vh;
			}

			video {
				max-width: 100%;
				height: 62rem;
			}
		}

		.OverFlowButton {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 2;
			display: flex;

			button {
				flex: 1;
				background: transparent;
				border: none;
			}
		}
	}

	.Right {
		max-width: 48rem;
		min-width: 48rem;
		width: 48rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		.RightTopContainer {
			width: 100%;
			// height: 62rem;
			flex: 2;
			fill: rgba(255, 255, 255, 0.53);
			backdrop-filter: blur(10rem);
			border-radius: 3rem;
			padding: 2.3rem;
			overflow: hidden;

			.CommentWrapper {
				height: 100%;
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.UserContainer {
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-right: 2rem;

					.ULeft {
						display: flex;
						align-items: center;
						gap: 1.2rem;

						.ImageContainer {
							width: 4.5rem;
							height: 4.5rem;
							overflow: hidden;
							border-radius: 50%;

							img {
								width: 100%;
								height: 100%;
							}
						}

						.TextWrapper {
							.name {
								color: #fff;
								font-family: "Poppins";
								font-size: 1.53rem;
								font-style: normal;
								font-weight: 600;
								line-height: 138.4%; /* 21.244px */
							}

							.UserName {
								color: #fff;
								font-family: "Poppins";
								font-size: 1rem;
								font-style: normal;
								font-weight: 500;
								line-height: 138.4%; /* 14.163px */
							}
						}
					}

					.URight {
						width: 4.5rem;
						height: 4.5rem;
						cursor: pointer;
					}
				}

				.ActionContainer {
					width: 100%;
					height: auto;
					display: flex;
					align-items: center;
					gap: 2rem;

					p {
						color: #fff;
						font-family: "Poppins";
						font-size: 2.4rem;
						font-style: normal;
						font-weight: 600;
						line-height: 123.4%; /* 30.366px */
						margin: 0;
					}

					.Like {
						height: 3rem;
						width: 8rem;
						display: flex;
						align-items: center;
						gap: 0.5rem;
						cursor: pointer;
						user-select: none;
						overflow: hidden;

						img {
							width: 4rem;
							height: 4rem;
						}
					}

					.Comment {
						height: 3rem;
						width: 8rem;
						display: flex;
						align-items: center;
						gap: 0.8rem;
						cursor: pointer;
						user-select: none;
						overflow: hidden;

						img {
							width: 3rem;
							height: 3rem;
						}
					}

					.Share {
						height: 3rem;
						width: 5rem;
						cursor: pointer;
						user-select: none;
						overflow: hidden;
					}
				}

				.CommentContainer {
					width: 100%;
					// flex: 1;
					height: 50%;
					flex-basis: 100%;
					display: flex;
					flex-direction: column;
					gap: 0.8rem;

					.TopBar {
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							color: #fff;
							font-family: "Poppins";
							font-size: 1.06rem;
							font-style: normal;
							font-weight: 500;
							line-height: 123.4%; /* 13.1px */
							margin: 0;
						}

						.TopLeft {
							display: flex;
							align-items: center;
							gap: 1.7rem;
							cursor: pointer;
						}

						.TopRight {
							display: flex;
							align-items: center;
							cursor: pointer;
						}
					}

					.BottomBar {
						width: 100%;
						height: 100%;
						overflow-y: scroll;

						&::-webkit-scrollbar {
							display: none;
						}

						.CommentContainerWrapper {
							margin-bottom: 1rem;
						}

						.CommentBox {
							width: 100%;
							height: auto;
							border-radius: 0.9rem;
							background: rgba(221, 224, 255, 0.16);
							display: flex;
							align-items: center;
							gap: 1rem;
							padding: 0 1.3rem;

							.CBLeft {
								display: flex;
								align-items: center;
								width: 100%;
								height: 100%;
								gap: 0.8rem;

								.ImageWrapper {
									width: 4rem;
									height: 4rem;
									border-radius: 50%;
									overflow: hidden;

									img {
										width: 100%;
										height: 100%;
									}
								}

								.TextWrapper {
									padding: 0.8rem 0;
									flex: 1;
									min-height: 6.6rem;

									.Name {
										color: #fff;
										font-family: "Poppins";
										font-size: 1.08rem;
										font-style: normal;
										font-weight: 400;
										line-height: 123.4%; /* 13.413px */
										margin-bottom: 0.6rem;
									}

									.Text {
										color: #fff;
										font-family: "Poppins";
										font-size: 1.4rem;
										font-style: normal;
										font-weight: 400;
										line-height: 123.4%; /* 15.284px */
										margin-bottom: 0.53rem;
									}

									.ButtonContainer {
										display: flex;
										align-items: flex-start;
										gap: 1.1rem;

										button {
											color: #fff;
											font-family: "Poppins";
											font-size: 0.8rem;
											font-style: normal;
											font-weight: 400;
											line-height: 123.4%; /* 8.734px */
											background: transparent;
											border: none;
											cursor: pointer;
											padding: 0.3rem 0.6rem;
											border-radius: 0.3rem;

											&:first-child {
												padding: 0.3rem 0rem;
											}
										}
									}
								}
							}

							.CBRight {
								height: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 0.5rem;

								p {
									margin: 0;
									color: #fff;
									font-family: "Poppins";
									font-size: 1.06rem;
									font-style: normal;
									font-weight: 400;
									line-height: 123.4%; /* 13.1px */
								}

								.Round {
									display: flex;
									align-items: center;
									justify-content: center;
									background: #fff;
									border-radius: 50%;
								}
							}
						}

						.SubCommentBoxWrapper {
							width: 100%;
							height: auto;
							margin-top: 0.8rem;
							display: flex;
							align-items: flex-end;
							justify-content: end;

							.SubCommentBox {
								width: 93%;
								height: auto;
								// height: 100%;
								border-radius: 0.5rem;
								background: rgba(221, 224, 255, 0.16);
								right: 0;
								display: flex;
								align-items: center;
								gap: 1rem;
								padding: 0 1.3rem;

								.CBLeft {
									display: flex;
									align-items: center;
									width: 100%;
									height: auto;
									gap: 0.8rem;

									.ImageWrapper {
										width: 4rem;
										height: 4rem;
										border-radius: 50%;
										overflow: hidden;

										img {
											width: 100%;
											height: 100%;
										}
									}

									.TextWrapper {
										padding: 0.8rem 0;
										flex: 1;
										min-height: 6.6rem;

										.Name {
											color: #fff;
											font-family: "Poppins";
											font-size: 1.08rem;
											font-style: normal;
											font-weight: 400;
											line-height: 123.4%; /* 13.413px */
											margin-bottom: 0.6rem;
										}

										.Text {
											color: #fff;
											font-family: "Poppins";
											font-size: 1.23rem;
											font-style: normal;
											font-weight: 400;
											line-height: 123.4%; /* 15.284px */
											margin-bottom: 0.53rem;
										}

										.ButtonContainer {
											display: flex;
											align-items: flex-start;
											gap: 1.1rem;

											button {
												color: #fff;
												font-family: "Poppins";
												font-size: 0.8rem;
												font-style: normal;
												font-weight: 400;
												line-height: 123.4%; /* 8.734px */
												background: transparent;
												border: none;
												cursor: pointer;
												padding: 0.3rem 0.6rem;
												border-radius: 0.3rem;

												&:first-child {
													padding: 0.3rem 0rem;
												}
											}
										}
									}
								}

								.CBRight {
									height: 100%;
									display: flex;
									align-items: center;
									justify-content: center;
									gap: 0.5rem;
								}
							}
						}
					}
				}

				.InputWrapper {
					display: flex;
					align-items: center;
					height: 5.6rem;
					gap: 0.5rem;
					position: relative;

					.InputContainer {
						flex: 1;
						height: 100%;
						padding: 0 1.4rem;
						border-radius: 1.1rem;
						background: rgba(217, 217, 217, 0.2);
						overflow: hidden;
						display: flex;
						align-items: center;

						input {
							width: 100%;
							height: 100%;
							background: transparent;
							border: none;
							outline: none;
							color: #fff;
							font-family: "Poppins";
							font-size: 1.63rem;
							font-style: normal;
							font-weight: 500;
							line-height: 123.4%; /* 20.136px */

							&::placeholder {
								color: #fff;
								font-family: "Poppins";
								font-size: 1.63rem;
								font-style: normal;
								font-weight: 500;
								line-height: 123.4%; /* 20.136px */
							}
						}

						.EmojiBtn {
							width: 2.3rem;
							height: 2.3rem;
							background: transparent;
							cursor: pointer;
							border: none;

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}

					.SubmitBtn {
						width: 8.3rem;
						height: 5.6rem;
						border-radius: 1.1rem;
						background: linear-gradient(137deg, #1b47c1 0%, #31dbc7 100%);
						border: none;
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.EmojiContainer {
						width: 100%;
						height:40rem;
						position: absolute;
						bottom: 6rem;
						left: 0rem;
					}
				}
			}
		}

		.RightBottomContainer {
			width: 100%;
			flex: 1;
			overflow: hidden;
			display: flex;
			align-items: center;
			gap: 1rem;

			button {
				width: 2.5rem;
				height: 2.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background: transparent;
				font-size: 2rem;
				cursor: pointer;
				border: none;
				color: #fff;
				font-size: 3rem;
				transition: all 0.3s ease-in-out;

				&:active {
					transform: scale(1.1);
				}
			}

			.SlideImages {
				height: 100%;
				flex: 1;
				// border: 1px dashed #0f0;
				overflow: hidden;
				display: flex;
				align-items: center;
				position: relative;

				.ImageWrapper {
					min-width: 20rem;
					max-width: 20rem;
					height: 20rem;
					overflow: hidden;
					transform: skewY(10deg);
					border-radius: 2rem;
					background: #ffffff;
					transition: all 0.3s ease-in-out;
					margin-left: -25%;
					box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);

					&.inActive {
						opacity: 0;
					}

					img {
						width: 102%;
						height: 102%;
					}

					video {
						width: 102%;
						height: 102%;
					}
				}
			}
		}
	}

	.ThreeDotWrapper {
		position: fixed;
		top: 4rem;
		left: 15.5rem;
		right: 4rem;
		bottom: 0rem;
		z-index: 10;
		border-radius: 0 2rem 2rem 0;
		background: rgba(0, 0, 0, 0.6);
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding: 6rem 11rem;

		.ButtonWrapper {
			background: #fff;
			width: 20rem;
			height: 30rem;
		}
	}
}
