.SwitchAccount {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 15;
	display: flex;
	align-items: center;
	justify-content: center;

	.Wrapper {
		width: 69.9rem;
		height: 57.6rem;
		border-radius: 2rem;
		background: rgba(161, 161, 161, 0.1);
		background: rgba(0, 0, 0, 0.4);
		backdrop-filter: blur(15px);
		padding: 4rem 2.9rem 4.2rem 3.7rem;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);

		.Row1 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 3rem;

			h1 {
				color: #fff;
				font-family: Poppins;
				font-size: 2.14rem;
				font-style: normal;
				font-weight: 600;
				line-height: 138.4%; /* 29.648px */
			}

			button {
				width: 3rem;
				height: 3rem;
				display: flex;
				align-items: center;
				justify-content: center;
				background: transparent;
				border: none;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.Row2 {
			flex: 1;
			display: flex;
			flex-direction: column;
			margin-bottom: 4rem;
			overflow-y: scroll;
			scrollbar-width: none;
			padding-right: 1rem;

			&::-webkit-scrollbar {
				width: 0.3rem;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 1rem;
				background: #3a0dbb;
			}
			.BoxWrapper {
				width: 100%;
				height: 12.1rem;
				padding: 1px;
				margin-bottom: 1.5rem;

				.Box {
					width: 100%;
					height: 100%;
					border-radius: 2rem 2rem 2rem 0rem;
					border: 1px solid rgba(51, 66, 255, 0.43);
					background: linear-gradient(90deg, rgba(17, 16, 24, 0) 0.28%, rgba(17, 16, 24, 0.51) 129.42%);
					background-blend-mode: overlay;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 1.8rem 3.9rem 1.9rem 3.3rem;

					.Left {
						display: flex;
						align-items: center;
						gap: 3.3rem;

						img {
							width: 8rem;
							height: 8rem;
							border-radius: 50%;
						}

						.TextWrapper {
							display: flex;
							flex-direction: column;

							h2 {
								color: #fff;
								font-family: Poppins;
								font-size: 1.7rem;
								font-style: normal;
								font-weight: 600;
								line-height: 2.6rem; /* 152.941% */
							}

							h4 {
								color: #fff;
								font-family: Poppins;
								font-size: 1.3rem;
								font-style: normal;
								font-weight: 400;
								line-height: 2rem; /* 153.846% */
							}
						}
					}

					.Right {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;

						input {
							width: 3.6rem;
							height: 3.6rem;
							appearance: none;
							border: 2px solid #fff;
							border-radius: 50%;
							padding: 5px;
							cursor: pointer;
							position: relative;

							&:checked {
								&::after {
									content: "";
									width: 1.8rem;
									height: 1.8rem;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									background-color: #fff;
									border-radius: 50%;
								}
							}
						}
					}
				}
			}
		}

		button {
			width: 100%;
			height: 9.1rem;
			border-radius: 1rem;
			background: #3a0dbb;
			border: none;
			cursor: pointer;
			transition: all 0.3s ease-in-out;
			color: #fff;
			font-family: Poppins;
			font-size: 1.9rem;
			font-style: normal;
			font-weight: 500;
			line-height: 138.4%; /* 26.347px */

			&:hover {
				background: #230083;
			}
		}
	}
}
