.Container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Container::-webkit-scrollbar {
    display: none;
}

.Insights {
    display: flex;
    flex-direction: column;
}

.InsightsContainer {
    width: 100%;
    height: 100%;
    padding: 0 3rem 0 5.5rem;
    display: flex;
    align-items: center;
    gap: 5.6rem;
}

.InsightsLeft {
    flex-basis: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;

}

.InsightsLeft::-webkit-scrollbar {
    display: none;
}

.InsightsLeft h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 3.64rem;
    line-height: 138.4%;
    color: #000000;
    margin-bottom: 1rem;
}

.InsightsLeft h4 {
    width: 67.495rem;
    height: 6.8rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 2.43rem;
    line-height: 138.4%;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 2rem;
}

/* FIXME: */
.GraphContainer {
    width: 100%;
    height: 66.91rem;
    border: .1rem solid #000;
    background: #3A0DBB;
    overflow: hidden;
}












.InsightsRight {
    width: 27.276rem;
    height: 100%;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
}

.InsightsRight select {
    width: 15.775rem;
    height: 8.325rem;
    background: #DDE0FF;
    border-radius: .87371rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 138.4%;
    color: #000000;
    outline: none;
    border: none;
    padding: 0 2rem;
    margin-bottom: 4rem;
    cursor: pointer;
}

.InsightsBoxContainer {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 5rem;
}

.InsightsBoxContainer::-webkit-scrollbar {
    display: none;
}

.InsightsBox {
    width: 27.276rem;
    height: 25.7rem;
    color: rgba(33, 33, 33, 0.48);
    border-radius: 2.21rem;
    padding: 1.18rem 10.2rem 7.4rem 4.3rem;
    margin-bottom: 2rem;
    border: .11061rem solid #9A9A9A;
    cursor: pointer;
}

.InsightsBox.active {
    background: linear-gradient(325.59deg, #2992E3 8.9%, #0F0AA4 100.7%);
    color: #fff;
}

.InsightsBox h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 4.64561rem;
    line-height: 138.4%;
}

.InsightsBox p {
    width: 9.027rem;
    height: 4.6rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.64745rem;
    line-height: 138.4%;
}


/* AudienceInsights Start */

.AudienceInsights {
    margin-top: 6.9rem;
    width: 100%;
    padding-right: 8.2rem;
}

.AudienceInsightsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AudienceInsightsTop h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 138.4%;
    color: #000000;
}

.AudienceInsightsTop p {
    display: block;
    margin-top: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 138.4%;
    color: rgba(0, 0, 0, 0.8);
}

.AudienceInsightsTop select {
    width: 10.863rem;
    height: 5.7rem;
    background: #DDE0FF;
    border-radius: .873719rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 138.4%;
    color: #000000;
    outline: none;
    border: none;
    padding: 0 2rem;
    margin-bottom: 4rem;
    cursor: pointer;
}

.AudienceInsightsBottom {
    display: flex;
    align-items: center;
}

.AudienceInsightsBottom p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 138.4%;
    color: rgba(0, 0, 0, 0.8);
}

.AudienceBottomLeftRight {
    width: 41.3rem;
    height: 33.6rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.1rem;
    padding-right: 8.6rem;
}

.AudienceBottomLeftRight button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 138.4%;
    color: rgba(0, 0, 0, 0.8);
    border: none;
    outline: none;
    background: transparent;
    padding: 1rem;
    cursor: pointer;
    margin-top: 3.9rem;
}

.bottomGroup {
    margin: 2.1rem 0 rem rem;
}

.bottomGroup div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottomGroup span {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.6rem;
    width: 100%;
    height: 1.8rem;
    position: relative;
}

.bottomGroup span p {
    display: block;
    position: relative;
    height: 100%;
    transition: all .2s ease;
}

.bottomGroup span p::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #3A0DBB;
    transition: all .2s ease;
    animation: wi 1s linear;
}

@keyframes wi {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

/* AudienceInsights End */
.ProfileVisitMain{
    gap: rem;
}
.ProfileVisit>img {
    width: 100%;
    height: 29.549rem;
    margin-bottom: 2.1rem;
}

.ProfileVisit .AudienceBottomLeftRight {
    width: 100%;
}