.container {
	/* position: relative; */
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	padding: 4rem 5rem 5rem 5rem;

	&::-webkit-scrollbar {
		display: none;
	}

	.header {
		height: 6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 10rem;

		p {
			font-weight: 600;
			font-size: 4.2rem;
			line-height: 5.8rem;
			color: #000;
		}

		button {
			background: transparent;
			border: none;
			cursor: pointer;
			padding: 1rem;
		}
	}

	.main-content {
		margin: 3rem 0;
		display: grid;
		grid-row-gap: 5.4rem;

		.info {
			display: grid;
			grid-row-gap: 3rem;

			.heading {
				font-weight: 600;
				font-size: 2.2rem;
				line-height: 3rem;
				color: #000;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				column-gap: 0.9rem;
				row-gap: 1.8rem;
			}

			.deactive {
				background-color: #ff3232;

				p {
					color: #ffffff;
				}
			}
		}
	}
}
