.main-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main-container .sub-container {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(10%, -50%);
    width: 70rem;
    border-radius: 6.3rem;
    overflow: hidden;
    display: grid;
    background: white;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
}

.main-container .sub-container .header {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.main-container .sub-container .header .text-container {
    padding: 4rem 6rem;
}

.main-container .sub-container .header .image {
    height: 100%;
    position: absolute;
    top: -1rem;
    right: 0;
}

.main-container .sub-container .header .image img {
    width: 100%;
    height: 100%;
}

.main-container .sub-container .header .text-container .heading {
    font-weight: 600;
    font-size: 3.3rem;
    line-height: 5rem;
    color: #000;
}

.main-container .sub-container .header .text-container .text {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #282828;
    width: 30rem;
    text-align: start;
}

.main-container .sub-container .form {
    padding: 2rem 7.8rem 6rem 6.3rem;
    display: grid;
}

.main-container .sub-container .form .input {
    display: flex;
    justify-content: space-around;
    padding: 0 3rem;
}

.main-container .sub-container .form .input .input-box {
    padding: 2rem;
    border-radius: 10px;
    border: none;
    width: 9.3rem;
    height: 8.6rem;
    background-color: #EEEEEE;
    font-size: 5rem;
    text-align: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.main-container .sub-container .form .actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-container .sub-container .form .actions .btn {
    background-color: #1943D8;
    color: #ffffff;
    font-weight: 500;
    width: 20rem;
    height: 6.2rem;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.798rem;
    line-height: 2.7rem;
    margin-top: 6rem;
    border-radius: 1rem;
    cursor: pointer;
}

.main-container .sub-container .form .actions .btn:disabled {
    background-color: #5a5e6a;
    cursor: default;
}

/* .main-container .sub-container .form .actions .btn:active{
    animation-timing-function: all ease-out;
} */

.main-container .sub-container .form .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 7rem;
}

.main-container .sub-container .form .footer .link {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.7rem;
    cursor: pointer;
    color: #000;
}

.main-container .sub-container .form .footer .time {
    display: flex;
    font-size: 1.8rem;
    line-height: 2.7rem;
}

.main-container .sub-container .form .footer .time .timer {
    color: #1731BA;
    font-size: 1.8rem;
    line-height: 2.7rem;
    margin-bottom: 0;
}

.text2{
    cursor: pointer;
    background: transparent;
    border: none;
    margin-right: .5rem;
    font-size: 1.8rem;
}
.text2:disabled{
    cursor: default;
}