.HasTags {
	background: #fff;
	min-height: 13.6rem;
	width: 100%;
	border-radius: 1rem;
	padding: 1.9rem 2rem;
	display: flex;
	align-items: center;
	gap: 2rem;

	.Left {
		height: 100%;
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		column-gap: 1rem;
		row-gap: 2rem;

		h2 {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2rem;
		}

		.Tag {
			min-width: 5rem;
			height: 4rem;
			padding: 2.2rem 2rem;
			border-radius: 11.017px;
			background: rgba(27, 71, 193, 0.1);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
            gap: 3rem;

			p {
                height: 2rem;
				color: #1b47c1;
				font-family: Poppins;
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			button {
				background: transparent;
				width: 2rem;
				height: 2rem;
				border: none;
				font-size: 1.6rem;
				cursor: pointer;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: #ef7272;
				}
			}
		}
	}

	.Right {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 4.1rem;

		p {
			color: #959595;
			font-family: Poppins;
			font-size: 1.14rem;
			font-style: normal;
			font-weight: 400;
			line-height: 138.4%; /* 15.889px */
		}

		.Wrapper {
			width: 25rem;
			height: 4.4rem;
			border: 1px solid #e9edf9;
			display: flex;
			border-radius: 0.69rem;
			overflow: hidden;
			align-items: center;

			input {
				height: 100%;
				width: 100%;
				border: none;
				outline: none;
				color: #4a4a4a;
				text-align: center;
				font-family: Poppins;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 500;
				line-height: 123.4%; /* 14.808px */
			}

			button {
				height: 100%;
				min-width: 6.3rem;
				background: #e9edf9;
				color: #1b47c1;
				text-align: center;
				font-family: Poppins;
				font-size: 1.39rem;
				font-style: normal;
				font-weight: 500;
				line-height: 123.4%; /* 17.247px */
				border: none;
				cursor: pointer;
			}
		}
	}
}
