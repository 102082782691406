/* Comment Container Start */
.CommentMainContainer {
	position: absolute;
	left: 0;
	bottom: 0%;
	width: 100%;
	height: 65%;
	z-index: 6;
	background: rgba(255, 255, 255, 0.13);
	backdrop-filter: blur(12.5px);
	border-radius: 0 0 2rem 2rem;
}

.CommentInnerContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.InputContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		gap: 2rem;

		.InputContainerInner {
			width: 60%;
			height: 4.5rem;
			background: rgba(217, 217, 217, 0.2);
			display: flex;
			align-items: center;
			gap: 2rem;
			padding: 0 2rem;
			border-radius: 1.1rem;
			overflow: hidden;

			&:focus-within {
				outline: 1px solid #3a0dbb;
			}

			input {
				width: 100%;
				height: 100%;
				font-size: 1.5rem;
				border: none;
				background: transparent;
				color: #fff;
				font-family: "Poppins";
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 500;
				line-height: 123.4%; /* 20.136px */
				outline: none;
			}

			.EmojiBtn {
				width: 2.3rem;
				height: 2.3rem;
				background: transparent;
				cursor: pointer;
				border: none;

				svg {
					width: 100%;
					height: 100%;
				}
			}
		}

		.SubmitBtn {
			width: 8.3rem;
			height: 4.5rem;
			border: none;
			font-weight: 600;
			color: #fff;
			cursor: pointer;
			border-radius: 1.1rem;
			background: linear-gradient(137deg, #1b47c1 0%, #31dbc7 100%);
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 80%;
				height: 80%;
			}
		}

		.EmojiContainer {
			width: 60%;
			height: 100%;
			position: absolute;
			bottom: 6rem;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.shadowContainer {
	position: absolute;
	top: -8rem;
	left: 0;
	right: 0;
	height: 8rem;
	background: linear-gradient(180deg, rgba(231, 233, 254, 0) 0%, #e7e9fe 97.51%);
}

.backBtnContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem 7.5rem 0rem 6rem;

	div {
		display: flex;
		align-items: center;
		gap: 2.5rem;

		&.downArrow {
			cursor: pointer;
			gap: 0.9rem;
		}

		img {
			cursor: pointer;
		}

		h3 {
			font-style: normal;
			font-weight: 500;
			font-size: 1.3rem;
			line-height: 123.4%;
			color: #ffffff;
		}
	}
}

.CommentBoxContainer {
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	padding-bottom: 5rem;
	padding-top: 2.5rem;

	&::-webkit-scrollbar {
		width: 0.5rem;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 1rem;
		background: rgb(5, 22, 255);
	}

	.MainCommentBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 60.2rem;
		width: 80%;
		margin: 0 auto;
		margin-bottom: 1rem;
		// border: 1px solid #f00;
	}
}

.CommentBox {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	padding: 1rem 14px;
	width: 100%;
	// height: 7.2rem;
	border-radius: 1rem;
	padding: 1rem 2.3rem 1rem 1.4rem;
	background: rgba(221, 224, 255, 0.38);

	.left {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0.7rem;

		.leftImage {
			width: 4.6rem;
			height: 4.6rem;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.leftText {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			p {
				font-style: normal;
				font-weight: 400;
				font-size: 1.2rem;
				line-height: 123.4%;
				color: #ffffff;
			}

			h4 {
				font-style: normal;
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 123.4%;
				color: #ffffff;
				overflow: hidden;
			}

			div {
				display: flex;
				flex-direction: row;
				align-items: center;
				// justify-content: space-around;
				font-style: normal;
				font-weight: 500;
				font-size: 0.95rem;
				line-height: 123.4%;
				// margin-top: 1rem;

				span {
					cursor: pointer;
					margin-right: 1rem;
					padding: 0.4rem 1rem;
					border-radius: 0.5rem;
					color: #fff;
				}
			}
		}
	}

	.right {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		p {
			font-style: normal;
			font-weight: 400;
			font-size: 1.2rem;
			line-height: 123.4%;
			color: #ffffff;
		}
	}
}

.SubCommentBox {
	width: 36rem;
	min-height: 5rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgba(221, 224, 255, 0.19);
	border-radius: 0.4rem;
	margin-top: 0.5rem;
	padding: 0 2rem 0 1rem;
	position: relative;

	&::after {
		content: "";
		background: rgba(0, 0, 0, 0.27);
		background: #fff;
		width: 1.3rem;
		height: 1px;
		position: absolute;
		top: 3rem;
		left: -2.5rem;
	}

	&::before {
		content: "";
		background: rgba(0, 0, 0, 0.27);
		background: #fff;
		width: 1px;
		height: 2.5rem;
		position: absolute;
		top: 0.5rem;
		left: -2.5rem;
		// transform: translateY(-50%);
	}

	.left {
		width: 100%;
		overflow: hidden;
		margin-right: 2rem;
		display: flex;
		gap: 0.7rem;
		align-items: center;

		.leftImage {
			width: 3.5rem;
			height: 3.5rem;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.leftText {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			padding: 1rem 0;

			p {
				font-weight: 400;
				font-size: 1rem;
				line-height: 123.4%;
				color: #fff;
			}

			h4 {
				width: 100%;
				font-weight: 400;
				font-size: 1.2rem;
				line-height: 100.4%;
				margin: 0;
				color: #fff;
			}

			div {
				margin: 0;
				align-items: flex-start;
				justify-content: start;

				span {
					font-weight: 400;
					font-size: 0.8rem;
					// line-height: 123.4%;
					color: #ffffff;
					cursor: pointer;
					padding: 0.4rem 1rem;
					border-radius: 0.5rem;
				}
			}
		}
	}

	.right {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		p {
			font-style: normal;
			font-weight: 400;
			font-size: 1.2rem;
			line-height: 123.4%;
			color: #ffffff;
		}
	}
}

.ReplySubCommentBox {
	width: 30rem;
	min-height: 5rem;
	width: 80%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgba(221, 224, 255, 0.19);
	border-radius: 0.4rem;
	margin-top: 0.5rem;
	padding: 0 2rem 0 1rem;
	position: relative;

	&::after {
		content: "";
		background: rgba(0, 0, 0, 0.27);
		background: #fff;
		width: 1.3rem;
		height: 1px;
		position: absolute;
		top: 3rem;
		left: -2.5rem;
	}

	&::before {
		content: "";
		background: rgba(0, 0, 0, 0.27);
		background: #fff;
		width: 1px;
		height: 2.5rem;
		position: absolute;
		top: 0.5rem;
		left: -2.5rem;
		// transform: translateY(-50%);
	}

	.left {
		width: 100%;
		overflow: hidden;
		margin-right: 2rem;
		display: flex;
		gap: 0.7rem;
		align-items: center;

		.leftImage {
			width: 3.5rem;
			height: 3.5rem;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.leftText {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			padding: 1rem 0;

			p {
				font-weight: 400;
				font-size: 1rem;
				line-height: 123.4%;
				color: #fff;
			}

			h4 {
				width: 100%;
				font-weight: 400;
				font-size: 1.2rem;
				line-height: 100.4%;
				margin: 0;
				color: #fff;
			}

			div {
				margin: 0;
				align-items: flex-start;
				justify-content: start;

				span {
					font-weight: 400;
					font-size: 0.8rem;
					padding: 0.4rem 1rem;
					color: #ffffff;

					&:last-child {
						cursor: pointer;
					}
				}
			}
		}
	}

	.right {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		p {
			font-style: normal;
			font-weight: 400;
			font-size: 1.2rem;
			line-height: 123.4%;
			color: #ffffff;
		}
	}
}

/* Comment Container End */
