.SharePopupWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.8);
	z-index: 4000;

	.SharePopup {
		width: 70rem;
		height: 60rem;
		border-radius: 1rem 1rem 2.2rem 2rem;
		background: rgba(48, 48, 48, 0.51);
		backdrop-filter: blur(8.71966552734375px);
		padding: 4rem;
		padding-bottom: 2rem;
		display: flex;
		flex-direction: column;
		gap: 4rem;

		.Top {
			width: 100%;
			height: 7rem;
			border-radius: 1.74rem;
			background: rgba(255, 255, 255, 0.1);
			backdrop-filter: blur(8.71966552734375px);
			display: flex;
			align-items: center;
			overflow: hidden;

			input {
				flex: 1;
				height: 100%;
				background: transparent;
				border: none;
				outline: none;
				padding-left: 2rem;
				color: #fff;
				font-family: "Poppins";
				font-size: 2.44rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 30.128px */
			}

			button {
				height: 100%;
				width: 8.5rem;
				border-radius: 1.74rem;
				background: rgba(255, 255, 255, 0.23);
				backdrop-filter: blur(8.71966552734375px);
				border: none;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s ease-in-out;

				svg {
					width: 4rem;
					height: 4rem;
				}

				&:hover {
					background: rgba(52, 60, 213, 0.5);
				}
			}
		}

		.Middle {
			display: flex;
			align-items: center;
			gap: 3.8rem;
			padding-left: 2rem;

			.ButtonWrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 1rem;

				button {
					width: 10rem;
					height: 10rem;
					border-radius: 50%;
					border: none;
					cursor: pointer;
					background: #888;
					transition: all 0.3s ease-in-out;
					display: flex;
					align-items: center;
					justify-content: center;

					&:hover {
						background: #075e54;
					}

					&.ThreeDot:hover {
						background: #1b47c1;
					}

					svg {
						width: 4rem;
						height: 4rem;
					}
				}

				p {
					color: #fff;
					font-family: "Poppins";
					font-size: 1.74rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin: 0;
				}
			}
		}

		.Bottom {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			p {
				color: #fff;
				font-family: "Poppins";
				font-size: 1.74rem;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				margin-bottom: 1.1rem;
			}

			.FollowWrapper {
				width: 100%;
				height: 100%;
				overflow-y: scroll;
				padding-right: 0.5rem;

				&::-webkit-scrollbar {
					width: 0.5rem;
				}

				&::-webkit-scrollbar-thumb {
					background: #1b47c1;
					border-radius: 1rem;
				}

				.Box {
					width: 100%;
					height: 8rem;
					border-radius: 1.74rem;
					background: rgba(255, 255, 255, 0.1);
					backdrop-filter: blur(8.71966552734375px);
					margin-bottom: 1rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 1.4rem 2rem 1.4rem 2rem;

					.left {
						display: flex;
						align-items: center;
						gap: 1.2rem;

						.InnerLeft {
							width: 6.5rem;
							height: 6.5rem;
							border-radius: 50%;
							overflow: hidden;

							img {
								width: 100%;
								height: 100%;
							}
						}

						.InnerRight {
							h2 {
								color: #fff;
								font-family: "Poppins";
								font-size: 2.4rem;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
							}

							h4 {
								color: #a0b4ec;
								font-family: "Poppins";
								font-size: 1.39rem;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
							}
						}
					}

					.right {
						width: 13rem;
						height: 100%;
						justify-content: center;
						align-items: center;
						border-radius: 1.74rem;
						background: #1b47c1;
						color: #fff;
						font-family: "Poppins";
						font-size: 1.39rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						border: none;
						cursor: pointer;
						transition: all 0.3s ease-in-out;

						&:hover {
							background: #0b2e8d;
						}
					}
				}
			}
		}
	}
}