.Container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.ADS {
	padding: 4.8rem 0 6rem 6rem;

	.Row1 {
		width: 100%;
		height: 10rem;
		margin-bottom: 3.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Left {
			display: flex;
			flex-direction: column;
			gap: 1.2rem;

			h1 {
				color: #000;
				font-family: Poppins;
				font-size: 4.2rem;
				font-style: normal;
				font-weight: 600;
				line-height: 138.4%; /* 58.128px */
			}

			p {
				color: #000;
				font-family: Poppins;
				font-size: 1.8rem;
				font-style: normal;
				font-weight: 500;
				line-height: 138.4%; /* 24.912px */
			}
		}

		.Right {
			width: 6rem;
			height: 6rem;
			border-radius: 1.4rem;
			background: #3a0dbb;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				background: #1e0070;
			}

			svg {
				width: 3.13rem;
				height: 3.13rem;
			}
		}
	}

	.Row2 {
		width: 100%;
		height: 12.5rem;
		margin-bottom: 1.6rem;
		display: flex;
		align-items: center;
		gap: 2.4rem;

		.Col {
			width: 35rem;
			height: 100%;
			border-radius: 1.5rem;
			background: #fff;
			display: flex;
			align-items: center;
			gap: 4rem;
			padding-left: 4.1rem;

			.Left {
				width: 5.4rem;
				height: 5.4rem;
				border-radius: 50%;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 2.9rem;
					height: 2.9rem;
				}
			}

			.Right {
				display: flex;
				flex-direction: column;
				gap: 0.2rem;

				h2 {
					color: #000;
					font-family: Poppins;
					font-size: 2.7rem;
					font-style: normal;
					font-weight: 700;
					line-height: 3.45rem; /* 127.273% */
				}

				p {
					color: rgba(60, 60, 67, 0.6);
					font-feature-settings: "clig" off, "liga" off;
					font-family: Poppins;
					font-size: 0.8rem;
					font-style: normal;
					font-weight: 400;
					line-height: 1.2rem; /* 150% */
				}
			}
		}
	}

	.Row3 {
		width: 100%;
		height: 42rem;
		margin-bottom: 2.4rem;
		display: flex;
		gap: 2.2rem;
		overflow: hidden;

		.Col1 {
			flex: 1.8;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			padding: 3.3rem 4.5rem;
			border-radius: 1.5rem;
			background: #fff;
			overflow: hidden;

			.Top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h2 {
					color: #000;
					font-family: Poppins;
					font-size: 1.8rem;
					font-style: normal;
					font-weight: 500;
					line-height: 2.8rem; /* 155.556% */
				}

				.Right {
					display: flex;
					align-items: center;
					gap: 3rem;

					input {
						border-radius: 0.4rem;
						border: 1px solid #ece9f1;
						background: #fff;
						color: #959595;
						font-feature-settings: "clig" off, "liga" off;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 2.2rem; /* 183.333% */
						outline: none;
					}

					select {
						border-radius: 0.4rem;
						border: 1px solid #ece9f1;
						background: #fff;
						color: #959595;
						font-feature-settings: "clig" off, "liga" off;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 2.2rem; /* 183.333% */
						outline: none;
					}
				}
			}

			.Bottom {
				flex: 1;
				overflow: hidden;
			}
		}

		.Col2 {
			flex: 1.2;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			padding: 2.6rem;
			border-radius: 1.5rem;
			background: #fff;
			overflow: hidden;

			.Top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h2 {
					color: #000;
					font-family: Poppins;
					font-size: 1.8rem;
					font-style: normal;
					font-weight: 500;
					line-height: 2.8rem; /* 155.556% */
				}

				.Right {
					display: flex;
					align-items: center;
					gap: 3rem;

					input {
						border-radius: 0.4rem;
						border: 1px solid #ece9f1;
						background: #fff;
						color: #959595;
						font-feature-settings: "clig" off, "liga" off;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 2.2rem; /* 183.333% */
						outline: none;
					}

					select {
						border-radius: 0.4rem;
						border: 1px solid #ece9f1;
						background: #fff;
						color: #959595;
						font-feature-settings: "clig" off, "liga" off;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 2.2rem; /* 183.333% */
						outline: none;
					}
				}
			}

			.Mid {
				flex: 1;
				display: flex;
				gap: 1rem;
				overflow: hidden;

				.Left {
					flex: 1;
					overflow: hidden;
				}

				.Right {
					width: 13.8rem;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;

					.TextWrapper {
						display: flex;
						gap: 1.5rem;

						span {
							display: block;
							width: 3rem;
							height: 0.8rem;
							border-radius: 1rem;
							margin-top: 0.6rem;
						}

						.Text {
							display: flex;
							flex-direction: column;
							gap: 0.4rem;

							p {
								color: #959595;
								font-feature-settings: "clig" off, "liga" off;
								font-family: Poppins;
								font-size: 1.4rem;
								font-style: normal;
								font-weight: 400;
								line-height: 2.2rem; /* 157.143% */
							}

							h5 {
								color: #11263c;
								font-feature-settings: "clig" off, "liga" off;
								font-family: Poppins;
								font-size: 1.4rem;
								font-style: normal;
								font-weight: 600;
								line-height: 2.2rem; /* 157.143% */
							}
						}
					}
				}
			}

			.Bottom {
				display: flex;
				justify-content: flex-end;

				button {
					border-radius: 5px;
					border: 1px solid rgba(0, 0, 0, 0.1);
					background: #f5f5f5;
					cursor: pointer;
					display: flex;
					width: 12rem;
					height: 3.9rem;
					justify-content: center;
					align-items: center;
					gap: 0.5rem;
					color: rgba(149, 149, 149, 0.4);
					font-family: Roboto;
					font-size: 1.26rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					svg {
						width: 2rem;
						height: 2rem;

						path {
							fill: #939393;
						}
					}
				}
			}
		}
	}

	.Row4 {
		width: 100%;
		height: 44rem;
		margin-bottom: 3rem;
		display: grid;
		grid-template-columns: 2fr 1fr 1fr;
		column-gap: 1.5rem;
		overflow: hidden;

		.Col1 {
			width: 100%;
			height: 100%;
			background: #fff;
			border-radius: 1.5rem;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			gap: 3rem;
			padding: 3rem;

			.Top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 2rem;

				h2 {
					color: #000;
					font-family: Poppins;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 500;
					line-height: 2rem; /* 125% */
					letter-spacing: 0.001rem;
				}

				input {
					border-radius: 0.4rem;
					border: 1px solid #ece9f1;
					background: #fff;
					color: #959595;
					font-feature-settings: "clig" off, "liga" off;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 2.2rem; /* 183.333% */
					outline: none;
				}

				select {
					border-radius: 0.4rem;
					border: 1px solid #ece9f1;
					background: #fff;
					color: #959595;
					font-feature-settings: "clig" off, "liga" off;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 2.2rem; /* 183.333% */
					outline: none;
				}
			}

			.Bottom {
				flex: 1;
				overflow: hidden;
			}
		}

		.Col2 {
			width: 100%;
			height: 100%;
			background: #fff;
			border-radius: 1.5rem;
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			overflow: hidden;
			padding: 2.5rem;

			.Top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h2 {
					color: #000;
					font-family: Poppins;
					font-size: 1.8rem;
					font-style: normal;
					font-weight: 500;
					line-height: 2.8rem; /* 155.556% */
				}

				.Right {
					display: flex;
					align-items: center;
					gap: 3rem;

					select {
						border-radius: 0.4rem;
						border: 1px solid #ece9f1;
						background: #fff;
						color: #959595;
						font-feature-settings: "clig" off, "liga" off;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 2.2rem; /* 183.333% */
						outline: none;
					}
				}
			}

			.Mid {
				flex: 1;
				display: flex;
				gap: 1rem;
				overflow: hidden;
			}
		}

		.Col3 {
			width: 100%;
			height: 100%;
			background: #fff;
			border-radius: 1.5rem;
			overflow: hidden;
			padding: 2.5rem 4.9rem 2.6em 3.8rem;
			display: flex;
			flex-direction: column;
			gap: 3rem;

			.Top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h1 {
					color: #000;
					font-family: Poppins;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 500;
					line-height: 20px; /* 125% */
					letter-spacing: 0.01px;
				}

				a {
					color: #959595;
					font-family: Roboto;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-decoration: none;
					display: flex;
					align-items: center;
					gap: 1rem;

					svg {
						width: 2rem;
						height: 2rem;
					}
				}
			}

			.Box {
				width: 100%;
				// height: 100%;
				background: #fff;
				overflow-y: scroll;
				scrollbar-width: thin;
				user-select: none;

				&::-webkit-scrollbar {
					display: none;
				}

				h2 {
					color: #414d55;
					font-family: Poppins;
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 700;
					line-height: 2rem; /* 142.857% */
					margin-bottom: 0.9rem;
				}

				p {
					color: #696d6e;
					font-family: Roboto;
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 400;
					line-height: 2rem; /* 142.857% */
					margin-bottom: 2.5rem;
				}

				.StarWrapper {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					margin-bottom: 2rem;

					svg {
						width: 2.4rem;
						height: 2.4rem;
					}
				}

				hr {
					margin-bottom: 1.6rem;
				}

				.Profile {
					display: flex;
					align-items: center;
					gap: 0.8rem;

					img {
						width: 3.8rem;
						height: 3.8rem;
						border-radius: 50%;
					}

					div {
						h4 {
							color: #000;
							text-align: center;
							font-family: Poppins;
							font-size: 1.4rem;
							font-style: normal;
							font-weight: 500;
							line-height: 123.4%; /* 17.293px */
						}

						h5 {
							color: rgba(0, 0, 0, 0.5);
							text-align: center;
							font-family: Poppins;
							font-size: 0.89rem;
							font-style: normal;
							font-weight: 400;
							line-height: 123.4%; /* 11.004px */
						}
					}
				}
			}
		}
	}

	.Row5 {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		.Top {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h1 {
				color: #000;
				font-family: Poppins;
				font-size: 2.4rem;
				font-style: normal;
				font-weight: 500;
				line-height: 138.4%; /* 33.216px */
			}

			a {
				text-decoration: none;
				color: #959595;
				font-family: Roboto;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				gap: 1rem;

				svg {
					width: 2rem;
					height: 2rem;
				}
			}
		}

		.Bottom {
			width: 100%;
			height: 47.7rem;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 2.8rem;
			overflow: hidden;

			.Col1 {
				width: 100%;
				height: 100%;
				background: #fff;
				border-radius: 1.5rem;
				overflow: hidden;
			}

			.Col2 {
				width: 100%;
				height: 100%;
				background: #fff;
				border-radius: 1.5rem;
				overflow: hidden;
			}

			.Col3 {
				width: 100%;
				height: 100%;
				background: #fff;
				border-radius: 1.5rem;
				overflow: hidden;
			}

			.Box {
				width: 100%;
				height: 47.7rem;
				border-radius: 1.5rem;
				background: #fff;
				display: flex;
				flex-direction: column;
				padding: 2.1rem 3.5rem 2.6rem 3.3rem;
				overflow: hidden;

				h1 {
					color: #000;
					text-align: center;
					font-family: Poppins;
					font-size: 2.8rem;
					font-style: normal;
					font-weight: 700;
					line-height: 120%; /* 33.6px */
					margin-bottom: 1.8rem;
				}

				p {
					color: #959595;
					text-align: center;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 150%; /* 18px */
					margin-bottom: 1rem;
					padding: 0 3rem;
				}

				img {
					width: 100%;
					height: 19rem;
					object-fit: contain;
					border-radius: 0.6rem;
					margin-bottom: 3rem;
				}

				.Amount {
					color: #000;
					text-align: center;
					font-family: Poppins;
					font-size: 4rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 0.5rem;
					margin-bottom: 3.5rem;

					span {
						font-size: 2rem;
						font-weight: 400;

						&:first-child {
							font-size: 2.3rem;
							font-weight: 500;
						}
					}
				}

				.Wrapper {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					h6 {
						color: #959595;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						position: absolute;
						left: 0;
					}

					button {
						width: 19rem;
						height: 4.2rem;
						border-radius: 0.8rem;
						background: rgba(27, 71, 193, 0.2);
						border: none;
						color: #1b47c1;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						text-transform: capitalize;
					}
				}

				.Wrapper2 {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					h6 {
						width: 8rem;
						padding: 1rem;
						border-radius: 0.5rem;
						display: flex;
						align-items: center;
						justify-content: center;
						background: rgba(255, 51, 51, 0.2);
						color: #f33;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						position: absolute;
						left: 0;
					}

					button {
						width: 19rem;
						height: 4.2rem;
						border-radius: 0.8rem;
						background: #3a0dbb;
						border: none;
						color: #fff;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						text-transform: capitalize;
					}
				}

				.Wrapper3 {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					button {
						// width: 19rem;
						// height: 4.2rem;
						border-radius: 0.8rem;
						background: transparent;
						border: none;
						color: #297c45;
						font-family: Poppins;
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-transform: capitalize;
					}
				}
			}
		}
	}
}
