.overlay {
    background-color: white;
    padding: 13rem 31.2rem 13rem 31.2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    width: 121rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overlay .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overlay .form .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.3rem;
    margin-bottom: 2.3rem;
}

.overlay .form h2 {
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 6.3rem;
    color: #444444;
}

.overlay .form .text p {
    text-align: center;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.3rem;
    color: #999999;
}

.overlay .form label {
    text-align: center;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 3.3rem;
    color: #444444;
    margin-bottom: 2.3rem;
}

.overlay .form .input {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 4.6rem;
}

.overlay .form .input .input-box {
    padding: .5rem;
    border-radius: 10px;
    border: none;
    width: 5rem;
    height: 5rem;
    background-color: #EEEEEE;
    font-size: 2.5rem;
    text-align: center;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.overlay .form .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 36.7rem;
}

.overlay .form .actions .btn {
    width: 100%;
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin-bottom: 2rem;
    background-color: #3A0DBB;
    color: #ffffff;
    border: none;
    filter: drop-shadow(0px, 4.07px, 20.35px rgba(0, 0, 0, 0.25));
}

.overlay .form .actions .text2 {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #444444;
}

.overlay .form .actions .text2 span {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #3A0DBB;
    margin-left: 0.5rem;
}
