.ChoseTemplate {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background: #011afb;
		border-radius: 1rem;
	}

	.Top {
		padding: 6.3rem 19rem 5rem 8.7rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		@media screen and (max-width: 1100px) {
			flex-direction: column;
			row-gap: 7rem;
			align-items: flex-start;
			padding: 6.3rem 1rem 5rem 5rem;
		}

		.Left {
			display: flex;
			align-items: center;
			gap: 3.1rem;

			img {
				width: 8rem;
			}

			div {
				h3 {
					color: #000;
					font-family: "Poppins";
					font-size: 4.3rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin-bottom: 0.9rem;
				}

				h4 {
					color: #666;
					font-family: "Poppins";
					font-size: 2.2rem;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
				}
			}
		}

		.Right {
			display: flex;
			align-items: center;
			gap: 1rem;

			button {
				cursor: pointer;
				border: none;
				font-family: "Poppins";
				font-size: 2rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				padding: 1.5rem 4rem;
				color: #545454;
				background: transparent;
				transition: all 0.2s ease-in-out;

				&:hover {
					background: #adadaf;
					color: #011afb;
				}

				&.active {
					background: #011afb;
					color: #fff;
				}
			}
		}

		.BackBtn{
			position: absolute;
			top: 4rem;
			right: 4rem;
			font-size: 3rem;
			cursor: pointer;
			font-weight: 700;

		}
	}

	.Bottom {
		// flex: 1;
		padding: 5rem 7rem;
		flex-shrink: 0;

		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 3rem;
		column-gap: 1.7rem;

		@media screen and (max-width: 1100px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media screen and (max-width: 600px) {
			grid-template-columns: repeat(1, 1fr);
		}

		.Box {
			width: 100%;
			height: 70rem;
			border-radius: 1rem;
			position: relative;
			padding: 0rem;
			transition: all 0.2s ease-in-out;
			overflow: hidden;

			&:hover {
				.Button {
					transform: scale((1.3));
				}
			}

			.Button {
				position: absolute;
				top: 2.1rem;
				right: 2.7rem;
				border-radius: 0.9375rem;
				background: #011afb;
				overflow: hidden;
				width: 7rem;
				height: 5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s ease-in-out;

				button {
					width: 100%;
					height: 100%;
					border: none;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					background: transparent;
					border: none;
					display: flex;
					align-items: center;
					justify-content: center;

					svg {
						width: 1.5rem;
						height: 1.5rem;
						transition: all 0.2s ease-in-out;
					}

					&:hover {
						background: #010a60;
						svg {
							transform: scale(1.2);
						}
					}
				}

				// &:hover{
				// 	transform: scale((1.3));
				// }
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.loadingContainer {
		height: 10rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}

	.PopupContainer {
		width: 100vw;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		background: rgba(231, 233, 254, 0.92);
		backdrop-filter: blur(5px);
		display: flex;
		align-items: center;
		justify-content: center;

		.Popup {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 5.4rem;
			padding: 6rem 8rem;
			border-radius: 1rem;
			background: #fff;

			.TopPopup {
				h1 {
					color: #000;
					font-family: "Poppins";
					font-size: 3.2rem;
					font-style: italic;
					font-weight: 600;
					line-height: normal;
					margin-bottom: 1em 0.9rem;
				}

				h2 {
					color: #434343;
					font-family: "Poppins";
					font-size: 1.8rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}

			.BottomPopup {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 1.6rem;

				button {
					width: 30rem;
					height: 6.8rem;
					cursor: pointer;
					border-radius: 1rem;
					font-family: "Poppins";
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					color: #878787;
					border: 1px solid #878787;
					background: transparent;
					transition: all 0.2s ease-in-out;

					&:hover {
						color: #fff;
						border: 1px solid #011afb;
						background: #011afb;
					}
				}
			}
		}
	}
}
