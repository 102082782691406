.InterestContainer {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background: #e7e9fe;
	display: flex;
	flex-direction: column;
	gap: 3rem;
	overflow-y: scroll;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	.Container1 {
		padding-top: 6rem;
		padding-left: 12rem;
		margin-bottom: 2rem;
	}
	.Container2 {
		padding-left: 12.1rem;
		position: relative;
		margin-bottom: 5rem;

		h1 {
			font-weight: 600;
			font-size: 4.3rem;
			line-height: 6.4rem;
			color: #000000;
			margin-bottom: 0.9rem;
		}
		p {
			font-weight: 300;
			font-size: 2.2rem;
			line-height: 3.3rem;
			color: #666666;
		}

		.searchBar{
			padding: 0 4rem;
			display: flex;
			justify-content: end;
		}
	}

	.Container3 {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		padding: 0 3.1rem;
		column-gap: 2.2rem;
		row-gap: 3.5rem;
		padding-bottom: 3rem;

		.Box {
			width: 100%;
			height: 37.3rem;
			border-radius: 1rem;
			cursor: pointer;
			position: relative;

			.InnerBox {
				width: 100%;
				height: 100%;
				overflow: hidden;
				border-radius: 1rem;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
			}

			h2 {
				font-weight: 500;
				font-size: 3.2rem;
				line-height: 4.8rem;
				color: #fefefe;
				position: absolute;
				bottom: 2.7rem;
				left: 4.7rem;
			}

			.Check {
				width: 3.5rem;
				height: 3.5rem;
				position: absolute;
				top: -2rem;
				right: 2rem;
				visibility: hidden;
			}

			&.active {
				border: 0.5rem solid #23a3ff;

				.Check {
					visibility: visible;
				}
			}
		}
	}
	.Container4 {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 6rem;

		button {
			width: 50rem;
			cursor: pointer;
			border: none;
			padding: 2rem;
			background: linear-gradient(183.75deg, #020aff -5.47%, #23a3ff 22.86%, #2489d2 56.43%, #020eff 88.42%);
			color: #fff;
			font-size: 4rem;
			transition: all 0.2s ease-in-out;
			border-radius: 2rem;

			&:hover {
				background: linear-gradient(183.75deg, #00058b -5.47%, #044e83 22.86%, #005491 56.43%, #00067e 88.42%);
			}
		}
	}
}

@media screen and (max-width: 1440px) {
	.InterestContainer {
		.Container1 {
			padding-top: 4rem;
			padding-left: 5.1rem;
			height: max-content;
			img {
				width: 10vw;
			}
		}
		.Container2 {
			padding-left: 0;
			text-align: center;
			margin-bottom: 0;
			h1 {
				font-size: 3.5rem;
			}
			p {
				font-size: 2rem;
				font-style: bold;
			}
		}
		.Container3 {
			grid-template-columns: repeat(2, 1fr);
		}
		.Container4 {
			button {
				background: var(--gradient1);
				color: white;
				width: 80%;
				border-radius: 12px;
				padding: 2.5rem 5.5rem;
				border: none;
				box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.25);

				&:hover {
					background: linear-gradient(183.75deg, #00058b -5.47%, #044e83 22.86%, #005491 56.43%, #00067e 88.42%);
				}
			}
		}
	}
}
