@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fuggles&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Croissant+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sedgwick+Ave+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tilt+Prism&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
	font-style: normal;
}

*::selection {
	color: rgb(255, 255, 255);
	background: #020aff;
}

html {
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
}

:root {
	--gradient1: linear-gradient(183.75deg, #020aff -5.47%, #23a3ff 22.86%, #2489d2 56.43%, #020eff 88.42%);
	--gradient2: linear-gradient(325.59deg, #2992e3 8.9%, #0f0aa4 100.7%);
	--gradient3: linear-gradient(235.29deg, #020aff -8.02%, #23a3ff 31.36%, #2489d2 68.48%, #020eff 92.57%);
	--gradient4: linear-gradient(58deg, #0f0aa4 23.31%, #2992e3 117.48%);
	--dark-blue: #3a0dbb;
	--violet1: #e7e9fe;
	--violet2: #afb6fd;
	--violet3: #dbdeff;
	--violet4: #e7e9fe;
	--violet5-gradient: linear-gradient(96.27deg, #f8f8f8 12.98%, #dce5ff 93.38%);
	--violet6: #ccd0ff;

	font-size: 62.5%;
}

#root {
	overflow: hidden;
	height: 100vh;
}

.Toastify {
	position: absolute;
	z-index: 99999;
	font-size: 16px;

	// .Toastify__toast-theme--colored.Toastify__toast--success
	// .Toastify__toast-theme--colored.Toastify__toast--error
	// .Toastify__toast-theme--colored.Toastify__toast--info
	// .Toastify__toast-theme--colored.Toastify__toast--warning
	// .Toastify__toast-theme--colored.Toastify__toast--default

	.Toastify__toast-theme--colored.Toastify__toast--default {
		border-radius: 10px !important;
		background: linear-gradient(134deg, rgba(30, 30, 30, 0.58) 0%, rgba(51, 51, 51, 0.58) 100%) !important;
		backdrop-filter: blur(2px) !important;
		border: 2px solid rgba(9, 225, 212, 1) !important;

		.Toastify__toast-body {
			background: linear-gradient(156deg, #09e1d4 0%, #00feef 100%) !important;
			background-clip: text !important;
			-webkit-background-clip: text !important;
			-webkit-text-fill-color: transparent !important;
		}
	}

	.Toastify__toast-theme--colored.Toastify__toast--success {
		border-radius: 10px !important;
		background: linear-gradient(134deg, rgba(30, 30, 30, 0.58) 0%, rgba(51, 51, 51, 0.58) 100%) !important;
		backdrop-filter: blur(2px) !important;
		border: 2px solid rgba(9, 225, 212, 1) !important;

		.Toastify__toast-icon {
			svg {
				fill: rgb(0, 255, 0) !important;
			}
		}

		.Toastify__toast-body {
			background: linear-gradient(156deg, #09e1d4 0%, #00feef 100%) !important;
			background-clip: text !important;
			-webkit-background-clip: text !important;
			-webkit-text-fill-color: transparent !important;
		}
	}

	.Toastify__toast-theme--colored.Toastify__toast--error {
		border-radius: 10px !important;
		background: linear-gradient(134deg, rgba(30, 30, 30, 0.58) 0%, rgba(51, 51, 51, 0.58) 100%) !important;
		backdrop-filter: blur(2px) !important;
		border: 2px solid rgba(9, 225, 212, 1) !important;

		.Toastify__toast-icon {
			svg {
				fill: rgb(255, 0, 0) !important;
			}
		}

		.Toastify__toast-body {
			background: linear-gradient(156deg, #09e1d4 0%, #00feef 100%) !important;
			background-clip: text !important;
			-webkit-background-clip: text !important;
			-webkit-text-fill-color: transparent !important;
		}
	}

	.Toastify__toast-theme--colored.Toastify__toast--warning {
		border-radius: 10px !important;
		background: linear-gradient(134deg, rgba(30, 30, 30, 0.58) 0%, rgba(51, 51, 51, 0.58) 100%) !important;
		backdrop-filter: blur(2px) !important;
		border: 2px solid rgba(9, 225, 212, 1) !important;

		.Toastify__toast-icon {
			svg {
				fill: rgb(225, 255, 0) !important;
			}
		}

		.Toastify__toast-body {
			background: linear-gradient(156deg, #09e1d4 0%, #00feef 100%) !important;
			background-clip: text !important;
			-webkit-background-clip: text !important;
			-webkit-text-fill-color: transparent !important;
		}
	}

	.Toastify__toast-theme--colored.Toastify__toast--info {
		border-radius: 10px !important;
		background: linear-gradient(134deg, rgba(30, 30, 30, 0.58) 0%, rgba(51, 51, 51, 0.58) 100%) !important;
		backdrop-filter: blur(2px) !important;
		border: 2px solid rgba(9, 225, 212, 1) !important;

		.Toastify__toast-icon {
			svg {
				fill: #3498db !important;
			}
		}

		.Toastify__toast-body {
			background: linear-gradient(156deg, #09e1d4 0%, #00feef 100%) !important;
			background-clip: text !important;
			-webkit-background-clip: text !important;
			-webkit-text-fill-color: transparent !important;
		}
	}
}

.App {
	width: 100%;
	height: 100vh;
	background: rgba(204, 208, 255, 1);
	border: 3.9rem solid #ccd0ff;
	border-bottom: none;

	.MainContainer {
		width: 100%;
		height: 100%;
		border-radius: 2rem;
		background: #e7e9fe;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		padding: 2rem 2rem 0;
		position: relative;
	}
}

@media screen and (max-width: 3840px) {
	:root {
		font-size: 140%;
	}
}
@media screen and (max-width: 2048px) {
	:root {
		font-size: 80%;
	}
}

// @media screen and (max-width: 1920px) {
// 	:root {
// 		font-size: 75%;
// 	}
// }
// @media screen and (max-width: 1920px) {
// 	:root {
// 		font-size: 70%;
// 	}
// }

@media screen and (max-width: 1920px) {
	:root {
		font-size: 69%;
	}
}

@media screen and (max-width: 1856px) {
	:root {
		font-size: 67%;
	}
}

@media screen and (max-width: 1792px) {
	:root {
		font-size: 64%;
	}
}

@media screen and (max-width: 1680px) {
	:root {
		font-size: 61%;
	}
}

@media screen and (max-width: 1600px) {
	:root {
		font-size: 58%;
	}
}

@media screen and (max-width: 1600px) {
	:root {
		font-size: 58%;
	}
}

@media screen and (max-width: 1440px) {
	:root {
		font-size: 52.5%;
	}
}

@media screen and (max-width: 1400px) {
	:root {
		font-size: 51%;
	}
}

@media screen and (max-width: 1366px) {
	:root {
		font-size: 49.8%;
	}
}

@media screen and (max-width: 1280px) {
	:root {
		font-size: 46%;
	}
}

@media screen and (max-width: 1152px) {
	:root {
		font-size: 44%;
	}
}

@media screen and (max-width: 1024px) {
	:root {
		font-size: 42%;
	}
}

@media screen and (max-width: 900px) {
	:root {
		font-size: 35%;
	}
}

@media screen and (max-width: 427px) {
	:root {
		font-size: 35%;
		--gradient3: linear-gradient(240.29deg, #020aff -5.02%, #23a3ff -3.64%, #2489d2 14.48%, #020eff 95.57%);
		--gradient1: linear-gradient(192.75deg, #020aff -5.47%, #23a3ff 22.86%, #2489d2 56.43%, #020eff 88.42%);
	}
}

// @media screen and (max-height: 680px) {
// 	:root {
// 		font-size: 40%;
// 	}
// }

// @media screen and (max-height: 600px) {
// 	:root {
// 		font-size: 35%;
// 	}
// }

// @media screen and (max-width: 800px) {
// 	:root {
// 		font-size: 30%;
// 	}
// }
