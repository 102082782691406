.StoryWrapper {
	width: 100%;
	height: 100%;
	padding: 0 1rem 1rem;
	overflow: hidden;

	.StoryUploadButtons {
		width: 100%;
		height: 100%;
		border-radius: 2rem;
		background: #fff;
		box-shadow: 0rem .5rem 0.8rem 0.3rem rgba(0, 0, 0, 0.2);
		position: relative;
		overflow: hidden;

		.PopupCloseWrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 8rem;
			height: 8rem;
			display: flex;
			align-items: center;
			padding-left: 2rem;
			// padding-top: 3rem;
			z-index: 5;

			.PopupClose {
				height: 4rem;
				width: 4rem;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				transform: rotate(180deg);

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.PopupContainer {
			height: 100%;
			width: 100%;
			background: #ffffff;
			display: flex;
			align-items: center;
			flex-direction: column;
			position: relative;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none;
			}

			.Controls {
				padding-top: 8rem;
				padding-bottom: 3rem;

				h1 {
					font-family: "Poppins";
					font-style: normal;
					font-weight: 600;
					font-size: 4.45rem;
					line-height: 6.7rem;
					text-align: center;
					color: #000000;
					margin-bottom: 9.7rem;
				}

				h4 {
					font-family: "Poppins";
					text-align: center;
					font-style: normal;
					font-weight: 600;
					font-size: 2.14rem;
					line-height: 3.2rem;
					color: #000000;
					margin-bottom: 3.4rem;
				}

				.buttonContainer {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
				}

				.buttonContainer .button {
					width: 35.8rem;
					height: 8.11rem;
					background: #dfecff;
					border-radius: 1.19rem;
					border: none;
					margin-bottom: 1rem;
					font-style: normal;
					font-weight: 600;
					font-size: 2.14rem;
					line-height: 3.2rem;
					color: #054bff;
					cursor: pointer;
					transition: all 0.3s ease-in-out;
					position: relative;

					span{
						position: absolute;
						bottom: 0;
						left: 0;
						height: 1rem;
						background: #0f0;
					}

					&:hover {
						color: #ffffff;
						background: #054bff;
					}

					&:disabled {
						color: #002992;
						background: #949494;
						cursor: default;
					}
				}
			}

			.TextBox {
				width: 100%;
				height: 100%;
				overflow: hidden;
				background: #fff;
				position: relative;
				display: flex;
				flex-direction: column;

				.controls {
					height: 8rem;
					user-select: none;
					margin-bottom: 1rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0rem 8rem 0rem 8rem;

					.Right {
						display: flex;
						align-items: center;
						gap: 2rem;
					}

					img {
						width: 4rem;
						height: 4rem;
					}

					.ArrowRight {
						cursor: pointer;
					}
				}

				.ColorPicker {
					position: absolute;
					top: 15.5rem;
					left: 17.5rem;
					z-index: 1;

					& > * {
						user-select: none;
					}

					&::after {
						content: "";
						background: #fff;
						width: 2.5rem;
						height: 2.5rem;
						position: absolute;
						top: 0rem;
						left: 50%;
						transform: rotate(45deg) translateX(-50%);
						z-index: -1;
					}
				}

				.Textarea {
					align-self: center;
					width: 55.9rem;
					resize: none;
					border: none;
					outline: none;
					height: 100%;
					padding: 3rem 0.5rem;
					font-style: normal;
					font-weight: 600;
					font-size: 10rem;
					// line-height: 10rem;
					line-height: 123.4%;
					color: #ffffff;
					background: transparent;
					text-align: center;
					overflow: hidden;

					&::-webkit-scrollbar {
						width: 0.5rem;
						cursor: pointer;
					}

					&::-webkit-scrollbar-thumb {
						background: #054bff;
						border-radius: 2rem;
						cursor: pointer !important;
					}
				}
			}

			.SelectedImageWrapper {
				width: 100%;
				height: 100%;
				background: #ffffff;
				display: flex;
				flex-direction: column;
				gap: 4rem;

				.Top {
					width: 100%;
					height: 8rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 8rem 0 8rem;

					h1 {
						color: #000;
						font-family: Poppins;
						font-size: 32px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}

					img {
						height: 4rem;
						width: 4rem;
						cursor: pointer;
					}
				}

				.Bottom {
					width: 100%;
					height: 100%;
					padding: 0 4rem;
					display: flex;
					flex-direction: column;
					gap: 1rem;
					overflow-y: scroll;

					&::-webkit-scrollbar {
						width: 0.5rem;
					}

					&::-webkit-scrollbar-thumb {
						background: #000;
					}

					.ImageSection {
						width: 100%;
						display: grid;
						grid-template-columns: repeat(3, 1fr);
						column-gap: 1.4rem;
						row-gap: 1.2rem;
						padding-right: 0.5rem;
						overflow-y: scroll;

						&::-webkit-scrollbar {
							width: 0.4rem;
						}

						&::-webkit-scrollbar-thumb {
							background: #054bff;
							border-radius: 1rem;
						}

						.ImageWrapper {
							width: 100%;
							height: 20rem;
							border-radius: 1rem;
							overflow: hidden;
							position: relative;

							.remove {
								position: absolute;
								top: 0.5rem;
								right: 0.5rem;
								width: 3rem;
								height: 3rem;
								border-radius: 50%;
								background: rgba(255, 255, 255, 0.3);
								box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 2rem;
								font-weight: 600;
								cursor: pointer;
								transition: all 0.3s ease-in-out;
								z-index: 2;

								&:hover {
									font-size: 2.5rem;
								}
							}

							img {
								width: 100%;
								height: 100%;
								z-index: 1;
							}
						}
					}

					.TextSection {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						gap: 2rem;
						padding: 2rem 0 2rem 0;
						cursor: grab;

						h1 {
							color: #000;
							font-family: Poppins;
							font-size: 21.483px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}

						h4 {
							color: #757575;
							font-family: Poppins;
							font-size: 21.483px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}

						button {
							color: #000;
							font-family: Poppins;
							font-size: 21.483px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							border: none;
							cursor: pointer;
							background: transparent;
							transition: all 0.3s ease-in-out;
							padding: 1rem 3rem;
							border-radius: 1rem;

							&:hover {
								background: #054bff;
								color: #fff;
								transform: scale(1.1);
							}
						}
					}
				}
			}

			.CropperMainWrapper {
				width: 100%;
				height: 100%;
				position: relative;
				background: #000000;
				box-shadow: 1rem 1rem 4.3rem rgba(0, 0, 0, 0.3);
				display: flex;
				flex-direction: column;
				gap: 2rem;

				.controlsBar {
					min-height: 8rem;
					max-height: 8rem;
					padding: 0 8rem 0 8rem;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.Right {
						display: flex;
						align-items: center;
						gap: 2rem;
					}

					img {
						height: 4rem;
						width: 4rem;
						cursor: pointer;
					}
				}

				.CropContainer {
					width: 100%;
					height: 100%;
					position: relative;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 2rem;

					.LeftBtn {
						position: absolute;
						top: 50%;
						left: 0rem;
						transform: translateY(-50%);
						width: 5rem;
						height: 5rem;
						display: flex;
						align-items: center;
						justify-content: center;
						background: transparent;
						border: none;
						cursor: pointer;
						transition: all 0.3s ease-in-out;

						&:hover {
							transform: translateY(-50%) scale(1.4);
						}
					}

					.RightBtn {
						position: absolute;
						top: 50%;
						right: 0rem;
						transform: translateY(-50%);
						width: 5rem;
						height: 5rem;
						display: flex;
						align-items: center;
						justify-content: center;
						background: transparent;
						border: none;
						cursor: pointer;
						transition: all 0.3s ease-in-out;

						&:hover {
							transform: translateY(-50%) scale(1.4);
						}
					}

					.img {
						max-width: 100%;
						max-height: 100%;
						object-fit: contain !important;
						margin: 0 auto;
						user-select: none;
						transition: all .3s ease-in-out;
					}

					.EffectPreview {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
						}

						div {
							position: absolute;
							background: transparent;

							.TextArea {
								width: 20rem;
								height: 5rem;
								background: transparent;
								border: none;
								z-index: 1;
							}
						}
					}
				}

				.BottomWrapper {
					width: 100%;
					height: 15rem;
					position: relative;

					.RemoveBtn {
						width: 14rem;
						height: 4.2rem;
						position: absolute;
						top: -5rem;
						right: 5rem;
						border-radius: 5.88344rem;
						background: rgba(255, 255, 255, 0.08);
						backdrop-filter: blur(4.706733226776123px);
						border: none;
						color: #fff;
						font-family: "Poppins";
						font-size: 1.5rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						cursor: pointer;
						transition: all 0.3s ease-in-out;

						&:hover {
							transform: scale(1.1);
						}
					}

					& > div {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 2rem;
					}

					.ButtonWrapper {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						gap: 2rem;
						padding: 0 4.8rem;
						// border: 3px solid #f00;

						button {
							padding: 1rem 3rem;
							border-radius: 10rem;
							background: rgba(255, 255, 255, 0.23);
							border: none;
							color: #fff;
							font-family: "Poppins";
							font-size: 1.5rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							cursor: pointer;
							transition: all 0.2s ease-in-out;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							svg {
								width: 2.6rem;
								height: 2.6rem;
							}

							&.active {
								color: #00ffc4;
								box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
								background: rgba(255, 255, 255, 0.4);
							}

							&:hover {
								color: #00ffc4;
								box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
								background: rgba(255, 255, 255, 0.4);
							}
						}
					}

					.RotateWrapper {
						display: flex;

						.Left {
							width: 100%;
							display: flex;
							align-items: center;
							flex-direction: column;
							// border: 1px solid #fff;

							input {
								width: 80%;
								cursor: pointer;
							}
							span {
								font-size: 2rem;
								color: #fff;
							}
						}

						.Right {
							width: 20rem;
							height: 100%;
							display: flex;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							button {
								width: 10rem;
								height: 4rem;
								font-size: 2rem;
								cursor: pointer;
								border: none;
								font-weight: 500;
								transition: all 0.3s ease-in-out;
								border-radius: 2rem;

								&:first-child {
									color: #fff;
									background: #1b47c1;
								}

								&:last-child {
									color: #1b47c1;
									background: #afb6fd;
								}

								&:hover {
									// color: #fff;
									transform: scale(1.1);
								}
							}
						}
					}

					.AspectRatioWrapper {
						display: flex;

						.Left {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 3rem;

							button {
								width: 6rem;
								height: 6rem;
								border-radius: 50%;
								border: none;
								cursor: pointer;
								font-size: 2rem;
								font-weight: 600;
								background: rgba(255, 255, 255, 0.23);
								color: #fff;
							}
						}

						.Right {
							width: 20rem;
							height: 100%;
							display: flex;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							button {
								width: 10rem;
								height: 4rem;
								font-size: 2rem;
								cursor: pointer;
								border: none;
								font-weight: 500;
								transition: all 0.3s ease-in-out;
								border-radius: 2rem;

								&:first-child {
									color: #fff;
									background: #1b47c1;
								}

								&:last-child {
									color: #1b47c1;
									background: #afb6fd;
								}

								&:hover {
									// color: #fff;
									transform: scale(1.1);
								}
							}
						}
					}

					.CropWrapper {
						display: flex;

						.Left {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 3rem;

							button {
								padding: 1rem 3rem;
								border-radius: 10rem;
								background: rgba(255, 255, 255, 0.23);
								border: none;
								color: #fff;
								font-family: "Poppins";
								font-size: 1.5rem;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								cursor: pointer;
								transition: all 0.2s ease-in-out;
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 1rem;

								svg {
									width: 2.6rem;
									height: 2.6rem;
								}

								&.active {
									color: #00ffc4;
									box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
									background: rgba(255, 255, 255, 0.4);
								}

								&:hover {
									color: #00ffc4;
									box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
									background: rgba(255, 255, 255, 0.4);
								}
							}
						}

						.Right {
							width: 20rem;
							height: 100%;
							display: flex;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							button {
								width: 10rem;
								height: 4rem;
								font-size: 2rem;
								cursor: pointer;
								border: none;
								font-weight: 500;
								transition: all 0.3s ease-in-out;
								border-radius: 2rem;

								&:first-child {
									color: #fff;
									background: #1b47c1;
								}

								&:last-child {
									color: #1b47c1;
									background: #afb6fd;
								}

								&:hover {
									// color: #fff;
									transform: scale(1.1);
								}
							}
						}
					}

					.EffectShowWrapper {
						display: flex;

						.Left {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 3rem;
							overflow: hidden;
							padding-left: 5rem;

							& > * {
								user-select: none;
							}

							.ImageWrapper {
								width: 9rem;
								height: 10rem;
								overflow: hidden;
								display: flex;
								flex-direction: column;
								align-items: center;
								gap: 0.5rem;
								cursor: pointer;

								img {
									width: 7rem;
									height: 7rem;
									border-radius: 50%;
									overflow: hidden;
									position: relative;
								}

								// .Image {
								// 	width: 7rem;
								// 	height: 7rem;
								// 	border-radius: 50%;
								// 	overflow: hidden;
								// 	position: relative;

								// 	img {
								// 		width: 100%;
								// 		height: 100%;
								// 		z-index: 1;
								// 	}

								// 	.EffectPre {
								// 		position: absolute;
								// 		top: 0;
								// 		left: 0;
								// 		width: 100%;
								// 		height: 100%;
								// 		z-index: 2;
								// 	}
								// }

								p {
									display: block;
									color: #fff;
									margin: 0;
									font-size: 1.7rem;
								}
							}
						}

						.Right {
							width: 20rem;
							height: 100%;
							display: flex;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							button {
								width: 10rem;
								height: 4rem;
								font-size: 2rem;
								cursor: pointer;
								border: none;
								font-weight: 500;
								transition: all 0.3s ease-in-out;
								border-radius: 2rem;

								&:first-child {
									color: #fff;
									background: #1b47c1;
								}

								&:last-child {
									color: #1b47c1;
									background: #afb6fd;
								}

								&:hover {
									// color: #fff;
									transform: scale(1.1);
								}
							}
						}
					}

					.AdjustmentWrapper {
						display: flex;

						.Left {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: space-evenly;
							gap: 1rem;
							
							.RangeMainWrapper {
								display: flex;
								flex-direction: column;
								gap: 1rem;
								
								.RangeWrapper {
									display: flex;
									align-items: center;
									justify-content: space-between;
									gap: 0rem;

									label {
										width: 11rem;
										color: #fff;
										font-size: 1.8rem;
									}

									input {
										width: 50%;
										cursor: pointer;
									}

									span {
										width: 3rem;
										text-align: center;
										color: #fff;
										font-size: 1.2rem;
									}
								}
							}
						}

						.Right {
							width: 20rem;
							height: 100%;
							display: flex;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							button {
								width: 10rem;
								height: 4rem;
								font-size: 2rem;
								cursor: pointer;
								border: none;
								font-weight: 500;
								transition: all 0.3s ease-in-out;
								border-radius: 2rem;

								&:first-child {
									color: #fff;
									background: #1b47c1;
								}

								&:last-child {
									color: #1b47c1;
									background: #afb6fd;
								}

								&:hover {
									// color: #fff;
									transform: scale(1.1);
								}
							}
						}
					}

					.TextWrapper {
						display: flex;

						.Left {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: space-evenly;
							gap: 1rem;
							position: relative;

							.draggableBtn {
								width: 12rem;
								height: 4rem;
								padding: 1rem;
								border-radius: 10rem;
								background: rgba(255, 255, 255, 0.23);
								border: none;
								color: #fff;
								font-family: "Poppins";
								font-size: 1.5rem;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								cursor: pointer;
								transition: all 0.2s ease-in-out;

								&:hover {
									color: #00ffc4;
									box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
								}
							}

							.fontSizeBtn {
								width: 7rem;
								height: 4rem;
								padding-left: 1rem;
								border-radius: 10rem;
								background: rgba(255, 255, 255, 0.23);
								border: none;
								color: #fff;
								font-family: "Poppins";
								font-size: 1.5rem;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								cursor: pointer;
								transition: all 0.2s ease-in-out;
								outline: none;
								border-right: 1rem solid transparent;

								&:hover {
									color: #00ffc4;
									box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
								}

								option {
									color: #000;

									&:hover {
										color: #000;
									}
								}
							}

							.rangeBtn {
								cursor: pointer;
							}

							.ColorBallBtn {
								width: 4rem;
								height: 4rem;
								border-radius: 50%;
								cursor: pointer;
								border: 1px solid #fff;
								position: relative;

								.ColorPicker {
									position: absolute;
									bottom: 6rem;
									left: 50%;
									transform: translateX(-50%);
								}
							}
						}

						.Right {
							width: 20rem;
							height: 100%;
							display: flex;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							button {
								width: 10rem;
								height: 4rem;
								font-size: 2rem;
								cursor: pointer;
								border: none;
								font-weight: 500;
								transition: all 0.3s ease-in-out;
								border-radius: 2rem;

								&:first-child {
									color: #fff;
									background: #1b47c1;
								}

								&:last-child {
									color: #1b47c1;
									background: #afb6fd;
								}

								&:hover {
									// color: #fff;
									transform: scale(1.1);
								}
							}
						}
					}

					.MirrorWrapper {
						display: flex;

						.Left {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: space-evenly;
							gap: 1rem;

							button {
								padding: 1rem 3rem;
								border-radius: 10rem;
								background: rgba(255, 255, 255, 0.23);
								border: none;
								color: #fff;
								font-family: "Poppins";
								font-size: 1.5rem;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								cursor: pointer;
								transition: all 0.2s ease-in-out;
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 1rem;

								svg {
									width: 2.6rem;
									height: 2.6rem;
								}

								&.active {
									color: #00ffc4;
									box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
									background: rgba(255, 255, 255, 0.4);
								}

								&:hover {
									color: #00ffc4;
									box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
									background: rgba(255, 255, 255, 0.4);
								}
							}
						}

						.Right {
							width: 20rem;
							height: 100%;
							display: flex;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 1rem;

							button {
								width: 10rem;
								height: 4rem;
								font-size: 2rem;
								cursor: pointer;
								border: none;
								font-weight: 500;
								transition: all 0.3s ease-in-out;
								border-radius: 2rem;

								&:first-child {
									color: #fff;
									background: #1b47c1;
								}

								&:last-child {
									color: #1b47c1;
									background: #afb6fd;
								}

								&:hover {
									// color: #fff;
									transform: scale(1.1);
								}
							}
						}
					}
				}
			}
		}
	}
}

.EffectNormal {
	filter: contrast(100%) brightness(100%) saturate(100%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px) !important;
	// mix-blend-mode: normal;
	// background: rgba(0, 0, 0, 0);
	// opacity: 1;
}

.Effect1977 {
	filter: contrast(110%) brightness(110%) saturate(130%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: screen;
	// background: rgba(243, 106, 188, 0.3);
	// opacity: 1;
}

.EffectAden {
	filter: contrast(90%) brightness(120%) saturate(85%) sepia(0%) hue-rotate(20deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: darken;
	// background: rgba(125, 105, 24, 0.1);
	// opacity: 1;
}

.EffectAmaro {
	filter: contrast(90%) brightness(110%) saturate(150%) sepia(0%) hue-rotate(-10deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: normal;
	// background: rgba(0, 0, 0, 0);
	// opacity: 1;
}

.EffectBrannan {
	filter: contrast(140%) brightness(100%) saturate(100%) sepia(50%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: lighten;
	// background: rgba(161, 44, 199, 0.31);
	// opacity: 1;
}

.EffectBrooklyn {
	filter: contrast(90%) brightness(110%) saturate(100%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: overlay;
	// background: rgba(127, 187, 227, 0.2);
	// opacity: 1;
}

.EffectClarendon {
	filter: contrast(120%) brightness(125%) saturate(100%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: overlay;
	// background: rgba(127, 187, 227, 0.2);
	// opacity: 1;
}

.EffectEarlybird {
	filter: contrast(90%) brightness(100%) saturate(100%) sepia(20%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: overlay;
	// background: radial-gradient(50% 50%, circle closest-corner, rgba(208, 186, 142, 1) 20, rgba(29, 2, 16, 0.2));
	// opacity: 1;
}

.EffectGingham {
	filter: contrast(105%) brightness(100%) saturate(100%) sepia(0%) hue-rotate(350deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: normal;
	// background: linear-gradient(to right, rgba(66, 10, 14, 0.2) 1, rgba(0, 0, 0, 0));
	// opacity: 1;
}

.EffectHudson {
	filter: contrast(90%) brightness(120%) saturate(110%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: multiply;
	// background: radial-gradient(50% 50%, circle closest-corner, rgba(255, 177, 166, 1) 50, rgba(52, 33, 52, 1));
	// opacity: 5;
}

.EffectInkwell {
	filter: contrast(110%) brightness(110%) saturate(100%) sepia(30%) hue-rotate(0deg) grayscale(100%) invert(0%) blur(0px);
	// mix-blend-mode: normal;
	// background: rgba(0, 0, 0, 0);
	// opacity: 1;
}

.EffectLofi {
	filter: contrast(150%) brightness(100%) saturate(110%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: multiply;
	// background: radial-gradient(50% 50%, circle closest-corner, rgba(0, 0, 0, 0) 70, rgba(34, 34, 34, 1));
	// opacity: 1;
}

.EffectMaven {
	filter: contrast(95%) brightness(95%) saturate(150%) sepia(25%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: hue;
	// background: rgba(3, 230, 26, 0.2);
	// opacity: 1;
}

.EffectPerpetua {
	filter: contrast(100%) brightness(100%) saturate(100%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: normal;
	background: linear-gradient(to bottom, rgba(0, 91, 154, 1) 1, rgba(61, 193, 230, 0));
	opacity: 0.5;
}

.EffectReyes {
	filter: contrast(85%) brightness(110%) saturate(75%) sepia(22%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: soft-light;
	// background: rgba(173, 205, 239, 1);
	// opacity: 0.5;
}

.EffectStinson {
	filter: contrast(75%) brightness(115%) saturate(85%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: soft-light;
	// background: rgba(240, 149, 128, 0.2);
	// opacity: 1;
}

.EffectToaster {
	filter: contrast(110%) brightness(110%) saturate(130%) sepia(0%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: screen;
	background: radial-gradient(50% 50%, circle closest-corner, rgba(15, 78, 128, 1) 1, rgba(59, 0, 59, 1));
	opacity: 0.5;
}

.EffectWalden {
	filter: contrast(110%) brightness(160%) saturate(100%) sepia(30%) hue-rotate(350deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: screen;
	// background: rgba(204, 68, 0, 1);
	// opacity: 0.33;
}

.EffectValencia {
	filter: contrast(108%) brightness(108%) saturate(100%) sepia(8%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: exclusion;
	// background: rgba(58, 3, 57, 1);
	// opacity: 0.5;
}

.EffectXpro2 {
	filter: contrast(100%) brightness(100%) saturate(100%) sepia(30%) hue-rotate(0deg) grayscale(0%) invert(0%) blur(0px);
	// mix-blend-mode: color-burn;
	// background: rgba(62, 162, 253, 0.5);
	// opacity: 1;
}
