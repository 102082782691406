.BusinessLogo {
	background: #fff;
	min-height: 15.8rem;
	width: 100%;
	border-radius: 1rem;
	padding: 2.1rem 3.4rem;
	display: flex;
	align-items: center;
	gap: 2rem;

	.Left {
		height: 100%;
		flex: 1;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 2rem;

        h2{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
        }

		.Logo {
			width: 11rem;
			height: 11rem;
			border-radius: 0.2rem;
			border: 1px solid rgba(0, 0, 0, 0.05);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.5rem;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			button {
				position: absolute;
				top: -1rem;
				right: -1rem;
				background: transparent;
				width: 2.5rem;
				height: 2.5rem;
				border: none;
				font-size: 1.8rem;
				cursor: pointer;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: #ef7272;
				}
			}
		}
	}

	.Right {
		height: 100%;
		width: 20rem;
		display: flex;
		flex-direction: column;
		gap: 2.9rem;

		.Up {
			display: flex;
			align-items: center;
			justify-content: space-between;

			div {
				display: flex;
				flex-direction: column;
				gap: 0.3rem;

				h3 {
					color: #000;
					font-family: Poppins;
					font-size: 1.72rem;
					font-style: normal;
					font-weight: 500;
					line-height: 138.4%; /* 23.834px */
				}

				h4 {
					color: #959595;
					font-family: Poppins;
					font-size: 1.14rem;
					font-style: normal;
					font-weight: 400;
					line-height: 138.4%; /* 15.889px */
				}
			}
		}

		button {
			width: 100%;
			height: 5.1rem;
			border: none;
			color: #1b47c1;
			text-align: center;
			font-family: Poppins;
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 400;
			line-height: 123.4%; /* 14.808px */
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;
		}
	}
}
