/* SmallHeader start */
.SmallHeader {
	height: 7.5rem;
	display: flex;
	justify-content: flex-end;
	padding-right: 3.8rem;
}

.SmallHeader-container {
	height: 100%;
	display: flex;
	gap: 2.5rem;
	align-items: center;
}

.SearchContainer {
	height: 4.8rem;
	display: flex;
	overflow: hidden;

	input {
		height: 100%;
		width: 0rem;
		background: transparent;
		outline: none;
		border: none;
		border-bottom: 1px solid #000;
		padding: 0;
		font-size: 2rem;
		transition: all 0.3s linear;
		margin-right: 0rem;
		// transform: translateX(30rem);

		&.active {
			width: 25rem;
			padding: 0 1rem;
			margin-right: 1rem;
			transform: translateX(0rem);
		}
	}
}

.SmallHeader-container button {
	border: none;
	background: transparent;
	cursor: pointer;

	&.btn {
		width: 4.8rem;
		height: 4.8rem;
		padding: 5;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.redDot {
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background: #f00;
			position: absolute;
			top: 0rem;
			right: 0rem;
			color: #fff;
			font-size: 100%;
		}

		&:hover {
			background: #ccd0ff;
			transition: all 0.2s ease-in;

			svg path {
				transition: all 0.1s ease-in;
				fill: #2992e3;
			}
		}
	}
}

.btn .SearchIcon {
	width: 2.8rem;
	height: 2.8rem;
}

.btn .NotificationIcon {
	width: 2.8rem;
	height: 2.8rem;
}

.SmallHeader-container .logo {
	width: 5.5rem;
	height: 5.5rem;

	img {
		width: 100%;
		height: 100%;
	}
}

/* Notification Start */
.NotificationContainerWrapper {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	// background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(3px);

	.NotificationContainer {
		width: 51.5rem;
		height: 100vh;
		position: absolute;
		top: 0;
		right: 0;
		overflow: hidden;
		box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.3);
		display: flex;
		flex-direction: column;
		border: 1px solid #fff;
		background: linear-gradient(91deg, rgba(255, 255, 255, 0.3) 1.74%, rgba(255, 255, 255, 0.15) 101.68%);
		box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(30px);

		.NotTop {
			width: 100%;
			height: 11.1rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 2rem 0 2.8rem;

			h1 {
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 600;
				font-size: 2.14rem;
				line-height: 3rem;
				color: #000;
			}

			button {
				background: transparent;
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 500;
				font-size: 1.4rem;
				line-height: 3rem;
				color: #999999;
				cursor: pointer;
				border: none;
				padding: 1rem 2rem;
				outline: none;
				border-radius: 1rem;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: #3a0dbb;
					color: #fff;
				}
			}
		}

		.NotMiddle {
			flex: 1;
			width: 100%;
			height: auto;
			overflow-y: scroll;
			padding-right: 2rem;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-thumb {
				background: #3a0dbb;
				border-radius: 1rem;
			}

			h2 {
				padding: 0 3rem;
			}

			.NotBox {
				width: 100%;
				// height: 7.3rem;
				border-radius: 0px 20px 20px 0px;
				border: 1px solid #fff;
				background: linear-gradient(270deg, rgba(57, 13, 184, 0.05) 57.6%, rgba(255, 255, 255, 0) 91.06%);
				margin-bottom: 0.8rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 2.4rem 3.1rem 2rem 1.6rem;
				gap: 1rem;
				cursor: pointer;

				&.isSeen {
					background: linear-gradient(270deg, rgba(67, 9, 236, .45) 57.6%, rgba(183, 175, 230, .7) 91.06%);
					h5 {
						p {
							color: #3b3434;
						}
					}
				}

				div {
					width: 100%;
					display: flex;
					align-items: center;
					gap: 2.5rem;

					div {
						width: 6rem;
						height: 6rem;
						border-radius: 50%;
						padding: 0.5rem;

						img {
							width: 100%;
							height: 100%;
						}

						.ProfileImage {
							border-radius: 50%;
						}
					}

					h5 {
						flex: 1;
						font-family: "Poppins";
						font-style: normal;
						font-weight: 500;
						font-size: 1.8rem;
						line-height: 3rem;
						color: #000000;

						h5 p {
							display: block;
							font-family: "Poppins";
							font-style: normal;
							font-weight: 400;
							font-size: 1.2rem;
							line-height: 3rem;
							color: #000000;
						}
					}
				}

				.timeText {
					width: 8rem;
					text-align: center;
					font-family: "Poppins";
					font-style: normal;
					font-weight: 400;
					font-size: 1.2rem;
					line-height: 3rem;
					color: #000000;
				}
			}
		}
	}
}

/* Notification End */

/* SmallHeader end */
