/* LiveIndex Start */
.LiveMainContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(153, 155, 167, 0.28);
    z-index: 10;
    display: flex;
    justify-content: flex-end;
}

.LiveClose {
    flex-basis: 50%;
}

.LiveContainer {
    height: 100%;
    width: 79.4rem !important;
    flex-basis: 79.4rem !important;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 1rem 1rem 4.3rem rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.LiveInnerContainer {
    width: 61rem;
    height: 100%;
    margin: 0 auto;
    overflow-y: scroll;
    padding-top: 4rem;
    scrollbar-color: #1B47C1 #fff;
    scrollbar-width: none;
}

.LiveInnerContainer::-webkit-scrollbar {
    width: rem;
}

.LiveInnerContainer::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #1B47C1;
}

.ImageContainer {
    width: 100%;
    height: 39.38rem;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 35.25%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.ImageBox {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ImageBox img {
    width: 100%;
    height: 100%;
}

.TextBox {
    width: 100%;
    height: 6.43rem;
    background: #161616;
    font-style: normal;
    font-weight: 400;
    font-size: 2.33rem;
    line-height: 2.8rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DescContainer {
    width: 100%;
    margin-top: 2.8rem;
    position: relative;
}

.DescContainer p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.94rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    color: #000000;
}

.DescContainer textarea {
    width: 100%;
    height: 14.03rem;
    background: #E7E9FE;
    border: .194969rem solid rgba(40, 40, 40, 0.21);
    border-radius: .97rem;
    margin-top: 1.5rem;
    padding: 1.5rem 2.2rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.94rem;
    line-height: 2.4rem;
    color: #000000;
    resize: none;
    outline-width: 0;

}

.DescContainer span {
    display: block;
    position: absolute;
    z-index: 5;
    right: 3rem;
    bottom: 2rem;

}


.FollowersContainer {
    margin-top: 2rem;
}

.topBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.topBox img {
    cursor: pointer;
}

.topBox p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    color: #000000;
}

.FollowersBox {
    width: 100%;
    height: 13.25rem;
    background: #E7E9FE;
    border: .194969rem solid #AFB6FD;
    border-radius: 1.94rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.9rem;
    margin-bottom: 1.4rem;
}

.LeftFollow {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.LeftFollow img {
    width: 9.358rem;
    height: 9.358rem;
    border-radius: 50%;
}

.LeftFollow div {
    display: flex;
    flex-direction: column;
}

.LeftFollow div h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2.72rem;
    line-height: 3.8rem;
    color: #000000;
}

.LeftFollow div h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.94rem;
    line-height: 3.8rem;
    color: #4A4A4A;
}

.RightFollow {
    width: 15.2rem;
    height: 6.6rem;
    background: #1B47C1;
    border-radius: .97rem;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.94rem;
    line-height: 3.8rem;
    color: #FFFFFF;
    border: none;
    transition: all .2s ease;
}

.RightFollow:hover {
    background: #002897;
}

.GoLiveBtnContainer {
    width: 100%;
    height: 10rem;
    background: #1B47C1;
    border-radius: .97rem;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 2.72rem;
    line-height: 4.1rem;
    color: #FFFFFF;
    cursor: pointer;
    transition: all .2s ease;
    border: none;
}

.GoLiveBtnContainer:hover {
    background: #002897;
}

/* LiveIndex End */


/* LiveShow Start */
.LiveShow {
    position: relative;
    overflow: hidden;
    align-items: flex-start;
    justify-content: space-between;
}

.liveDivContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 8;
}

.liveDivContainer img {
    width: 100%;
    height: 100%;
}

.LiveShowTop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9.6rem 6.8rem;
    z-index: 10;
}

.LiveShowTop img {
    cursor: pointer;
    padding: .5rem;
}

.LiveShowTop button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: #FF6767;
    background: transparent;
    border: none;
    outline: none;
    position: relative;
    width: 6.5rem;
    height: 4.82rem;
    width: 17.4rem;
    cursor: pointer;

}

.LiveShowTop button::after {
    content: "LIVE";
}

.LiveShowTop button::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30%;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #FF6767;
    animation: blink infinite 1s;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.LiveShowTop button:hover::before {
    display: none;
}

.LiveShowTop button:hover::after {
    content: "END LIVE";
}

.LiveShowTop button:hover {
    transition: all .4s ease-in;
    width: 17.4rem;
    height: 4.82rem;
    background: #FF3C3C;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: #FFFFFF;
    content: 'Live End';
}

.LiveShowTop button:active::after {
    position: relative;
    top: .1rem;
}

.LiveShowTop button:active {
    transition: all .0s ease-in;
    box-shadow: inset 0 0 8px 2px rgba(0, 0, 0, 0.3);
}

.LiveShowBottom {
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 8.2rem 10rem 7.7rem;
}

.LiveShowBottomLeft {
    width: 31.982rem;
}

.LiveShowBottomLeft h4 {
    width: 12.9rem;
    height: 3.2rem;
    background: rgba(247, 247, 247, 0.1);
    border-radius: 10rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.8rem;
}

.LiveShowBottomLeft p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.22rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    margin-bottom: 1rem;
}

.LiveShowBottomLeft div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .7rem;
}

.LiveShowBottomLeft div span {
    height: 2.97rem;
    background: rgba(219, 222, 255, 0.05);
    border-radius: .73rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.22rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem 1.2rem;
}

.LiveShowBottomRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 4.87rem;
}

.LiveShowBottomRight img {
    cursor: pointer;
}

.LiveShowBottomRight img:active {
    transition: all .2s ease-in-out;
    transform: scale(1.5);
}

/* LiveEndPopup Start */

.LiveEndPopup {
    width: 61rem;
    height: 36.8rem;
    background: #fff;
    box-shadow: 0 0 2rem 1rem rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border-radius: 1rem;
    padding: 5.4rem 6.7rem;
}

.LiveEndPopup h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 4.2rem;
    line-height: 5.2rem;
    color: #000000;
}

.LiveEndPopup h1 span {
    display: block;
    font-weight: 700;
}

.LiveEndPopup div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.6rem;
}

.LiveEndPopup div button {
    width: 21.8rem;
    height: 6.2rem;
    background: #D7DAFF;
    border-radius: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #000000;
    border: none;
    outline: none;
    cursor: pointer;
}

.LiveEndPopup div button:last-child {
    background: #F23F3F;
    color: #fff;
}

.LiveEndPopup div button:hover {
    transition: all .3s ease-in-out;
    background: #b9b9b9;
}

.LiveEndPopup div button:last-child:hover {
    transition: all .3s ease-in-out;
    background: #881c1c;
}


/* LiveEndPopup End */




/* LiveShow End */




/* LiveAgain Start */
.TextBox2 {
    background: #EE4949;
}

/* LiveAgain End */