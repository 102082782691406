.LiveReviewWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #00000068;
	z-index: 10;
	display: flex;
	justify-content: flex-end;
	align-items: end;
	padding-right: 3.9rem;

	.LiveReview {
		height: calc(100% - 3.9rem);
		width: 63rem;
		background: #fff;
		overflow: hidden;
		border-radius: 0 2.2rem 0 0;
		// display: flex;
		// flex-direction: column;
		scrollbar-width: thin;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 0.3rem;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 1rem;
			background: #3a0dbb;
		}

		.Row1 {
			width: 100%;
			height: 44rem;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			padding: 4.6rem 11rem 3rem 12rem;
			overflow: hidden;

			.Top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h2 {
					color: #000;
					font-family: Poppins;
					font-size: 1.8rem;
					font-style: normal;
					font-weight: 500;
					line-height: 2.8rem; /* 155.556% */
				}

				.Right {
					display: flex;
					align-items: center;
					gap: 3rem;

					select {
						border-radius: 0.4rem;
						border: 1px solid #ece9f1;
						background: #fff;
						color: #959595;
						font-feature-settings: "clig" off, "liga" off;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 2.2rem; /* 183.333% */
						outline: none;
					}
				}
			}

			.Mid {
				flex: 1;
				display: flex;
				gap: 1rem;
				overflow: hidden;
			}
		}

		.Row2 {
			padding-left: 7.7rem;
			display: flex;
			align-items: center;
			gap: 1rem;
			margin-bottom: 3.7rem;

			button {
				width: 11.5rem;
				height: 3.9rem;
				border-radius: 0.5rem;
				border: none;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				color: rgba(0, 0, 0, 0.4);
				font-family: Roboto;
				font-size: 1.26rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

				&:hover {
					color: rgba(255, 255, 255, 1);
					background: #3a0dbb;
				}

				&.Active {
					background: #3a0dbb;
					color: rgba(255, 255, 255, 1);
				}
			}
		}

		.Row3 {
			flex: 1;
			padding: 0 6.1rem 5rem 7.6rem;

			.Box {
				width: 100%;
				height: 9.7rem;
				margin-bottom: 1.9rem;
				background: #f5f5f5;
				padding: 0 2.6rem 0 1.4rem;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.Wrapper {
					display: flex;
					align-items: center;
					gap: 2.5rem;

					img {
						width: 7.2rem;
						height: 7.2rem;
						border-radius: 50%;
					}

					.Text {
						display: flex;
						flex-direction: column;
						gap: 0.9rem;

						h3 {
							color: #000;
							font-family: Poppins;
							font-size: 2.2rem;
							font-style: normal;
							font-weight: 500;
							line-height: 138.4%; /* 30.448px */
						}

						p {
							color: #4a4a4a;
							font-family: Poppins;
							font-size: 1.4rem;
							font-style: normal;
							font-weight: 500;
							line-height: 138.4%; /* 19.376px */
						}
					}
				}

				button {
					color: #3a0dbb;
					font-family: Poppins;
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 500;
					line-height: 138.4%; /* 19.376px */
					background: transparent;
					cursor: pointer;
					border: none;
				}
			}
		}
	}
}
