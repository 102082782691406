.backdrop {
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100vh;
    z-index: 25;
    overflow: hidden;
    background-color: rgba(153, 155, 167, 0.28);
    /* border: 10px solid #f00; */
    user-select: none;
}

.modal {
    position: fixed;
    z-index: 20;
    padding: 5rem;
}