.info-container {
	width: 45.6rem;
	min-height: 8.7rem;
	border-radius: 0.9rem;
	background: #ffffff;
	padding: 1.3rem 2.4rem 0.89rem 2.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.subject {
		display: flex;
		flex-direction: column;
		gap: 0.3rem;

		.heading {
			font-family: Poppins;
			font-size: 1.72rem;
			font-style: normal;
			font-weight: 500;
			line-height: 138.4%; /* 23.834px */
		}

		.content {
			color: #959595;
			font-family: Poppins;
			font-size: 1.2rem;
			font-style: normal;
			font-weight: 400;
			line-height: 138.4%; /* 15.888px */
		}
	}

	.Right {
		display: flex;
		align-items: center;
		gap: 3.1rem;

		.action {
			background: transparent;
			border: none;
			font-weight: 500;
			font-size: 1.49rem;
			line-height: 2.1rem;
			color: #1b47c1;
		}

		.switch {
		}
	}
}
