.main-container {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.container {
    width: 85rem;
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(-0%, -50%);
    padding: 4rem 14.1rem 5rem 10.7rem;
    background: white;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
}

.container .heading {
    font-weight: 600;
    font-size: 4.2rem;
    line-height: 6.3rem;
    margin-bottom: .5rem;
}

.container .text1 {
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 3.3rem;
}

.container .logo-section {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .logo-section .logo-container {
    width: 11.5rem;
    height: 11.5rem;
    border-radius: 50%;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
}

.container .logo-section .logo-container .logo {
    width: 100%;
    height: 100%;
}

.container .input {
    display: grid;
    grid-row-gap: 1rem;
}

.container .input .input-field {
    display: flex;
    flex-direction: column;
}

.container .input .text2 {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2.1rem;
    color: #444444;
    margin-bottom: .9rem;
}

.container .input .input-box {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #000000;
    background: #FFFFFF;
    border: 1px solid #909090;
    border-radius: 10px;
    padding: 1.9rem;
}

.container .actions {
    margin: 3rem 0 0 0;
    display: grid;
    grid-row-gap: 2rem;
}

.choseTem {
    font: inherit;
    background: #F2F2F2;
    border: 1px solid #CCCCCC;
    border-radius: 1rem;
    color: #CCCCCC;
    padding: 1.9rem 3rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

.choseTem:hover,
.choseTem:active {
    background: #3A0DBB;
    border: 1px solid #3A0DBB;
}

.skipNow {
    font: inherit;
    background: #F2F2F2;
    border: 1px solid #CCCCCC;
    border-radius: 1rem;
    color: #CCCCCC;
    padding: 1.9rem 3rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    transition: all .3s ease-in-out;
}
.activeBtn {
    background: #3A0DBB;
    border: 1px solid #3A0DBB;
}

/* .skipNow:hover,
.skipNow:active {
    background: #3A0DBB;
    border: 1px solid #3A0DBB;
} */

.skipNow:disabled {
    background-color: #5a5e6a;
    cursor: default;
}