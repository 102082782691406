.main-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main-container .sub-container {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(10%, -50%);
    width: 70rem;
    border-radius: 6.3rem;
    overflow: hidden;
    display: grid;
    background: white;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
}

.main-container .sub-container .header {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.main-container .sub-container .header .text-container {
    padding: 7rem 8rem 5rem 8rem;
}

.main-container .sub-container .header .image {
    height: 100%;
    position: absolute;
    top: -1rem;
    right: 0;
}

.main-container .sub-container .header .image img {
    width: 100%;
    height: 100%;
}

.main-container .sub-container .header .text-container .heading {
    font-weight: 600;
    font-size: 3.3rem;
    line-height: 5rem;
    color: #000;
}

.main-container .sub-container .header .text-container .text {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #282828;
    width: 27rem;
}

.main-container .sub-container .form {
    padding: 0 7.8rem 6rem 8rem;
    display: grid;
    grid-row-gap: 1rem;
}

.main-container .sub-container .form .input {
    display: flex;
    flex-direction: column;
}

.main-container .sub-container .form .input .label {
    font-weight: 500;
    font-size: 1.798rem;
    line-height: 2.7rem;
    margin-bottom: 0.5rem;
}

.main-container .sub-container .form .input .input-box {
    padding: 1.6rem;
    border: none;
    background-color: #EEEEEE;
}

.main-container .sub-container .form .actions {
    /* padding: 1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-container .sub-container .form .actions .btn {
    background-color: #1943D8;
    color: #ffffff;
    font-weight: 500;
    width: 20rem;
    height: 5rem;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.798rem;
    line-height: 2.7rem;
    margin-top: 3.5rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.main-container .sub-container .form .actions .btn:hover {
    background-color: #001d84;
}
.main-container .sub-container .form .actions .btn:disabled {
    background-color: #5a5e6a;
    cursor: default;
}

.main-container .sub-container .form .link {
    text-align: center;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.7rem;
    cursor: pointer;
    margin-top: 5rem;
    color: #000;
}