.CardDiv {
    width: 72.4rem;
    height: 49.8rem;
    position: relative;
}

.sign-up {
    width: 46.1rem;
    height: 100%;
    border-radius: 3.84rem;
    overflow: hidden;
    background: white;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.sign-up .image {
    height: 65%;
    position: relative;
}

.sign-up .image img {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: all .5s ease-in-out;
}

.sign-up .text-area {
    margin: 1rem 7rem 0 5rem;
    display: grid;
    /* row-gap: 1rem; */
}

.sign-up .text-area .signup-heading {
    font-weight: 600;
    font-size: 4.1rem;
    line-height: 6.2rem;
    transition: all .5s ease-in-out;
    color: #000;
}

.sign-up .text-area .text {
    font-weight: 500;
    font-size: 1.24rem;
    line-height: 1.9rem;
    transition: all .5s ease-in-out;
    color: #282828;
}

.sign-in {
    width: 30.1rem;
    height: 32.5rem;
    border-radius: 2.5rem;
    overflow: hidden;
    background: white;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 9.8rem;
    right: 0;
    z-index: 2;
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.sign-in .image2 {
    height: 65%;
    position: relative;
}

.sign-in .image2 img {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transition: all .5s ease-in-out;
}

.sign-in .text-area {
    margin: 0 4.5rem 0 3.3rem;
    display: grid;
    /* row-gap: 1rem; */
}

.sign-in .text-area .signin-heading {
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 4rem;
    transition: all .5s ease-in-out;
    color: #000;
}

.sign-in .text-area .text2 {
    font-weight: 500;
    font-size: .78rem;
    line-height: 1.2rem;
    transition: all .5s ease-in-out;
    color: #282828;
}

/* on mouse hover */

.sign-up:hover {
    transition: all .5s ease-in-out;
    z-index: 3;
}

.sign-in:hover {
    width: 46.1rem;
    height: 100%;
    top: 0;
    right: 0;
}


.sign-in:hover .text-area .signin-heading {
    font-weight: 600;
    font-size: 4.1rem;
    line-height: 6.2rem;
    color: #000;
}

.sign-in:hover .text-area .text2 {
    font-weight: 500;
    font-size: 1.24rem;
    line-height: 1.9rem;
    color: #282828;
}

.active {
    width: 30.1rem;
    height: 32.5rem;
    top: 9.8rem;
}



.active .text-area .signup-heading {
    font-weight: 600;
    font-size: 2.6rem;
    line-height: 4rem;
    color: #000;
}

.active .text-area .text {
    font-weight: 500;
    font-size: .77rem;
    line-height: 1.2rem;
}