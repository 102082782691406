.Container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.YourAds {
	padding: 4.8rem 0 6rem 6.3rem;

	.Row1 {
		width: 100%;
		height: 10rem;
		margin-bottom: 6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Left {
			display: flex;
			flex-direction: column;
			gap: 1.2rem;

			h1 {
				color: #000;
				font-family: Poppins;
				font-size: 4.2rem;
				font-style: normal;
				font-weight: 600;
				line-height: 138.4%; /* 58.128px */
			}
		}

		.Right {
			width: 6rem;
			height: 6rem;
			border-radius: 1.4rem;
			background: #3a0dbb;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				background: #1e0070;
			}

			svg {
				width: 3.13rem;
				height: 3.13rem;
			}
		}
	}

	.Row2 {
		display: flex;
		align-items: center;
		gap: 4rem;
		margin-bottom: 1.4rem;

		button {
			color: rgba(58, 13, 185, 0.36);
			font-family: Poppins;
			font-size: 2.4rem;
			font-style: normal;
			font-weight: 500;
			line-height: 138.4%; /* 33.216px */
			background: transparent;
			border: none;
			padding-bottom: 1rem;
			position: relative;
			transition: all 0.3s ease-in-out;
			cursor: pointer;

			&.Active {
				color: #3a0dbb;

				&::after {
					content: "";
					height: 0.5rem;
					width: 100%;
					background: #3a0dbb;
					position: absolute;
					left: 0;
					bottom: 0;
					border-radius: 1rem;
					transition: all 0.3s ease-in-out;
				}
			}
		}
	}

	.Row3 {
		min-height: 40rem;
		position: relative;
		transition: all 0.5s ease-in-out;
		overflow: hidden;
		display: flex;

		.Current {
			min-width: 100%;
			max-width: 100%;
			width: 100%;
			height: 100%;
			transition: all 0.5s ease-in-out;
		}

		.Coming {
			min-width: 100%;
			max-width: 100%;
			width: 100%;
			height: 100%;
			transition: all 0.5s ease-in-out;
		}

		.Previous {
			color: #000;
			font-family: Poppins;
			font-size: 2.4rem;
			font-style: normal;
			font-weight: 500;
			line-height: 138.4%; /* 33.216px */
			margin-bottom: 1.8rem;
		}

		.row1 {
			margin-bottom: 1.7rem;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			column-gap: 2.8rem;
		}

		.row2 {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			column-gap: 2.8rem;
			row-gap: 3rem;
		}

		.Box {
			width: 100%;
			// max-width: 45.6rem;
			height: 47.7rem;
			border-radius: 1.5rem;
			background: #fff;
			display: flex;
			flex-direction: column;
			padding: 2.1rem 3.5rem 2.6rem 3.3rem;
			overflow: hidden;

			h1 {
				color: #000;
				text-align: center;
				font-family: Poppins;
				font-size: 2.8rem;
				font-style: normal;
				font-weight: 700;
				line-height: 120%; /* 33.6px */
				margin-bottom: 1.8rem;
			}

			p {
				color: #959595;
				text-align: center;
				font-family: Poppins;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 18px */
				margin-bottom: 1rem;
				padding: 0 3rem;
			}

			img {
				width: 100%;
				height: 19rem;
				object-fit: contain;
				border-radius: 0.6rem;
				margin-bottom: 3rem;
			}

			.Amount {
				color: #000;
				text-align: center;
				font-family: Poppins;
				font-size: 4rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				margin-bottom: 3.5rem;

				span {
					font-size: 2rem;
					font-weight: 400;

					&:first-child {
						font-size: 2.3rem;
						font-weight: 500;
					}
				}
			}

			.Wrapper {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				h6 {
					color: #959595;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					position: absolute;
					left: 0;
				}

				button {
					width: 19rem;
					height: 4.2rem;
					border-radius: 0.8rem;
					background: rgba(27, 71, 193, 0.2);
					border: none;
					color: #1b47c1;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-transform: capitalize;
				}
			}

			.Wrapper2 {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				h6 {
					width: 8rem;
					padding: 1rem;
					border-radius: 0.5rem;
					display: flex;
					align-items: center;
					justify-content: center;
					background: rgba(255, 51, 51, 0.2);
					color: #f33;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					position: absolute;
					left: 0;
				}

				button {
					width: 19rem;
					height: 4.2rem;
					border-radius: 0.8rem;
					background: #3a0dbb;
					border: none;
					color: #fff;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-transform: capitalize;
				}
			}

            .Wrapper3{
                position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

                button {
					// width: 19rem;
					// height: 4.2rem;
					border-radius: 0.8rem;
					background: transparent;
					border: none;
					color: #297C45;
					font-family: Poppins;
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					text-transform: capitalize;
				}
            }
			
		}
	}
}
