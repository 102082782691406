.landing-bg {
    width: 100%;
    height: 100vh;
    background: var(--gradient3);
    padding: 3rem 11rem;
}

.landing-bg .logo-container {
    position: relative;
}

.landing-bg .logo-container .big-logo {
    height: 90vh;
    /* width: 60vw; */
    /* border: 10px solid #000; */
}

.landing-bg .logo-container .small-logo {
    position: absolute;
    top: 35%;
    left: 10%;
    width: 20rem;
}

@media screen and (max-width: 427px) {

    .landing-bg {
        width: 100%;
        height: 100vh;
        background: var(--gradient3);
        padding: 2rem 2rem;
    }

    .landing-bg .logo-container {
        position: relative;
        display: flex;
        justify-content: center;
        /* background-color: red; */
    }

    .landing-bg .logo-container .small-logo {
        position: static;
        width: 23rem;
    }
}