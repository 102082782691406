.Container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.UsersReviews {
	padding: 3.6rem 0 6rem 12rem;

	.Row1 {
		width: 100%;
		height: 10rem;
		margin-bottom: 3.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Left {
			display: flex;
			flex-direction: column;
			gap: 1.2rem;

			h1 {
				color: #000;
				font-family: Poppins;
				font-size: 4.2rem;
				font-style: normal;
				font-weight: 600;
				line-height: 138.4%; /* 58.128px */
			}
		}

		.Right {
			width: 6rem;
			height: 6rem;
			border-radius: 1.4rem;
			background: #3a0dbb;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				background: #1e0070;
			}

			svg {
				width: 3.13rem;
				height: 3.13rem;
			}
		}
	}

	.Row2 {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 2.5rem;
		row-gap: 5.9rem;

		.Box {
			width: 30rem;
			min-height: 33rem;
			border-radius: 1.6rem;
			background: #fff;
			padding: 4.2rem 3.2rem 3.5rem 3.2rem;
			overflow-y: scroll;
			scrollbar-width: thin;

			&::-webkit-scrollbar {
				display: none;
			}

			h2 {
				color: #414d55;
				font-family: Poppins;
				font-size: 1.4rem;
				font-style: normal;
				font-weight: 700;
				line-height: 2rem; /* 142.857% */
				margin-bottom: 0.9rem;
			}

			p {
				color: #696d6e;
				font-family: Roboto;
				font-size: 1.4rem;
				font-style: normal;
				font-weight: 400;
				line-height: 2rem; /* 142.857% */
				margin-bottom: 2.5rem;
			}

			.StarWrapper {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				margin-bottom: 2rem;

				svg {
					width: 2.4rem;
					height: 2.4rem;
				}
			}

			hr {
				margin-bottom: 1.6rem;
			}

			.Profile {
				display: flex;
				align-items: center;
				gap: 0.8rem;
                
				img {
					width: 3.8rem;
					height: 3.8rem;
					border-radius: 50%;
				}
                
				div {
					h4 {
						color: #000;
						text-align: center;
						font-family: Poppins;
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 500;
						line-height: 123.4%; /* 17.293px */
					}

					h5 {
						color: rgba(0, 0, 0, 0.5);
						text-align: center;
						font-family: Poppins;
						font-size: .89rem;
						font-style: normal;
						font-weight: 400;
						line-height: 123.4%; /* 11.004px */
					}
				}
			}
		}
	}
}
