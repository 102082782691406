.Main {
	background: #ffffff !important;
}

.Wrapper {
    background-color: white;
	padding: 6rem 12rem 9rem 9.9rem;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
	border-radius: 1rem;

	&::-webkit-scrollbar {
		width: 0.6rem;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 2rem;
		background: #020eff;
	}

	h2 {
		font-weight: 500;
		font-size: 4.2rem;
		line-height: 6.3rem;
		color: #444444;
		margin-bottom: 2.5rem;
	}

	article {
		font-weight: 400;
		font-size: 1.7rem;
		line-height: 3.3rem;
		color: #999999;
		text-align: justify;
	}

	p {
		font-weight: 400;
		font-size: 1.7rem;
		line-height: 3.3rem;
		color: #999999;
		text-align: justify;
		margin-bottom: 1rem;
	}

	.li {
		font-weight: 600;
		font-size: 2.2rem;
		line-height: 3.3rem;
		color: #999999;
		text-align: justify;
		margin-bottom: 1rem;
		text-transform: capitalize;
	}
	.h6 {
		font-weight: 600;
		font-size: 2.2rem;
		line-height: 3.3rem;
		color: #999999;
		text-align: justify;
		margin-bottom: 2rem;
		margin-top: 5rem;
		text-transform: capitalize;
		text-align: center;
	}

	.padding {
		padding-left: 5rem;
	}
}
