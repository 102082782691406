.main-container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.main-container .sub-container {
	position: absolute;
	top: 50%;
	right: 10%;
	transform: translate(10%, -50%);
	width: 70rem;
	border-radius: 6.3rem;
	overflow: hidden;
	display: grid;
	grid-row-gap: 1rem;
	background: white;
	box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.25);
}

.main-container .sub-container .header {
	display: flex;
	position: relative;
}

.main-container .sub-container .header .image {
	height: 100%;
	position: absolute;
	top: -1rem;
	right: 0;
}

.main-container .sub-container .header .image img {
	width: 100%;
	height: 100%;
}

.main-container .sub-container .header .text-container {
	padding: 4rem 6rem;
}

.main-container .sub-container .header .text-container .heading {
	font-weight: 400;
	font-size: 4.2rem;
	line-height: 5rem;
	color: #000;
}

.main-container .sub-container .header .text-container .text {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #282828;
	width: 27rem;
}

.main-container .sub-container .form {
	padding: 0 7.8rem 6rem 6.3rem;
	display: grid;
	grid-row-gap: 1rem;
}

.main-container .sub-container .form .input-container {
	display: grid;
	grid-row-gap: 1rem;
}

.main-container .sub-container .form .input-container .input {
	display: flex;
	flex-direction: column;
	position: relative;
}

.ArrowBtn {
	position: absolute;
	z-index: 5;
	top: 0rem;
	right: -7.5rem;
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0px 2.480964183807373px 12.404821395874023px 0px rgba(0, 0, 0, 0.15);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5rem;
	font-weight: 100;
	text-decoration: none;
	color: #000;
	user-select: none;

	@media screen and (max-width: 900px) {
		top: 1rem;
	}
}

.main-container .sub-container .form .input-container .input .label {
	font-weight: 400;
	font-size: 1.798rem;
	line-height: 2.7rem;
	margin-bottom: 0.5rem;
}

.main-container .sub-container .form .input-container .forget-remember {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.main-container .sub-container .form .input-container .forget-remember .remember {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.main-container .sub-container .form .input-container .forget-remember .remember .radio-button {
	width: 3rem;
	height: 3rem;
	margin-right: 1rem;
}

.main-container .sub-container .form .input-container .forget-remember .link {
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 2.7rem;
	color: #666666;
	cursor: pointer;
}

.main-container .sub-container .form .input-container .forget-remember .remember {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.8rem;
	line-height: 2.7rem;
}

.main-container .sub-container .form .input-container .input .input-box {
	padding: 1.6rem;
	border: none;
	background-color: #eeeeee;
}

.input .inputPass {
	border: none;
	background-color: #eeeeee;
	position: relative;

	input {
		width: 100%;
		height: 100%;
		padding: 1.6rem;
		background: transparent;
		border: none;
	}

	span {
		position: absolute;
		top: 50%;
		right: 3.5rem;
		transform: translateY(-50%);
		cursor: pointer;
	}
}

.main-container .sub-container .form .actions {
	/* padding: 1rem; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-container .sub-container .form .actions .btn {
	background-color: #1943d8;
	color: #ffffff;
	font-weight: 500;
	width: 20rem;
	height: 6.2rem;
	border: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 1.798rem;
	line-height: 2.7rem;
	margin-top: 2rem;
	cursor: pointer;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.main-container .sub-container .form .actions .btn:disabled {
	background-color: #5a5e6a;
	cursor: default;
}

.main-container .sub-container .form .design {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 3rem;
}

.main-container .sub-container .form .design .line {
	width: 8rem;
	height: 0.2rem;
	background-color: black;
}

.main-container .sub-container .form .design .continue {
	font-weight: 600;
	font-size: 1.798rem;
	line-height: 2.7rem;
	color: #000;
}

.main-container .sub-container .form .footer {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.main-container .sub-container .form .footer button {
	background: transparent;
	border: none;
	cursor: pointer;
}
