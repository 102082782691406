.AdsDetailPopupWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #00000068;
	z-index: 10;
	display: flex;
	justify-content: flex-end;
	align-items: end;
	padding-right: 3.9rem;

	.AdsDetailPopup {
		height: calc(100% - 3.9rem);
		width: 63rem;
		background: #fff;
		overflow: hidden;
		border-radius: 0 2.2rem 0 0;
		// display: flex;
		// flex-direction: column;
		scrollbar-width: thin;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 0.3rem;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 1rem;
			background: #3a0dbb;
		}

		.Row1 {
			padding: 3rem 10.3rem 0 5.2rem;
			margin-bottom: 0rem;

			.Box {
				width: 100%;
				height: 42rem;
				border-radius: 1.5rem;
				background: #fff;
				display: flex;
				flex-direction: column;
				padding: 2.1rem 3.5rem 2.6rem 3.3rem;
				overflow: hidden;

				h1 {
					color: #000;
					font-family: Poppins;
					font-size: 2.8rem;
					font-style: normal;
					font-weight: 700;
					line-height: 120%; /* 33.6px */
					margin-bottom: 1.8rem;
				}

				p {
					color: #959595;
					font-family: Poppins;
					font-size: 1.2rem;
					font-style: normal;
					font-weight: 400;
					line-height: 150%; /* 18px */
				}

				img {
					width: 100%;
					height: 19rem;
					object-fit: contain;
					border-radius: 0.6rem;
					margin-bottom: 1rem;
				}

				.Amount {
					color: #000;
					font-family: Poppins;
					font-size: 4rem;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 0.5rem;
					margin-bottom: 0.6rem;

					span {
						font-size: 2rem;
						font-weight: 400;

						&:first-child {
							font-size: 2.3rem;
							font-weight: 500;
						}
					}
				}

				.Wrapper {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					h6 {
						color: #959595;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						position: absolute;
						left: 0;
					}

					button {
						width: 19rem;
						height: 4.2rem;
						border-radius: 0.8rem;
						background: rgba(27, 71, 193, 0.2);
						border: none;
						color: #1b47c1;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						text-transform: capitalize;
					}
				}

				.Wrapper2 {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					h6 {
						width: 8rem;
						padding: 1rem;
						border-radius: 0.5rem;
						display: flex;
						align-items: center;
						justify-content: center;
						background: rgba(255, 51, 51, 0.2);
						color: #f33;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						position: absolute;
						left: 0;
					}

					button {
						width: 19rem;
						height: 4.2rem;
						border-radius: 0.8rem;
						background: #3a0dbb;
						border: none;
						color: #fff;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						text-transform: capitalize;
					}
				}

				.Wrapper3 {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					button {
						// width: 19rem;
						// height: 4.2rem;
						border-radius: 0.8rem;
						background: transparent;
						border: none;
						color: #297c45;
						font-family: Poppins;
						font-size: 1.4rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-transform: capitalize;
					}
				}
			}
		}

		.Row2 {
			padding: 0 0 0 7rem;
			display: flex;
			align-items: center;
			gap: 4rem;

			button {
				color: #959595;
				text-align: center;
				font-family: Poppins;
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-transform: capitalize;
				background: transparent;
				cursor: pointer;
				position: relative;
				border: none;
				display: block;
				padding: 0 1rem;
				padding-bottom: 1.4rem;

				&:last-child {
					color: #ff5757;
				}

				&.Active {
					color: #3a0dbb;

					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 0.6rem;
						border-radius: 100px;
						background: #3a0dbb;
					}
				}
			}
		}

		.Row3 {
			flex: 1;
			overflow: hidden;
			display: flex;
			position: relative;

			.Box {
				width: 100%;
				height: 9.7rem;
				margin-bottom: 1.9rem;
				background: #f5f5f5;
				padding: 0 2.6rem 0 1.4rem;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.Wrapper {
					display: flex;
					align-items: center;
					gap: 2.5rem;

					img {
						width: 7.2rem;
						height: 7.2rem;
						border-radius: 50%;
					}

					.Text {
						display: flex;
						flex-direction: column;
						gap: 0.9rem;

						h3 {
							color: #000;
							font-family: Poppins;
							font-size: 2.2rem;
							font-style: normal;
							font-weight: 500;
							line-height: 138.4%; /* 30.448px */
						}

						p {
							color: #4a4a4a;
							font-family: Poppins;
							font-size: 1.4rem;
							font-style: normal;
							font-weight: 500;
							line-height: 138.4%; /* 19.376px */
						}
					}
				}

				button {
					color: #3a0dbb;
					font-family: Poppins;
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 500;
					line-height: 138.4%; /* 19.376px */
					background: transparent;
					cursor: pointer;
					border: none;
				}
			}

			.Col {
				max-width: 100%;
				min-width: 100%;
				height: 100%;
				// overflow-y: scroll;
				// scrollbar-width: none;
				transition: all 0.5s ease-in-out;
				padding: 0 6rem 3rem;
				// overflow-y: scroll;
				// scrollbar-width: thin;

				// &::-webkit-scrollbar {
				// 	display: none;
				// }
			}

			.Col1 {
				display: flex;
				flex-direction: column;
				gap: 6rem;

				.Top {
					display: grid;
					grid-template-columns: 1fr 1fr;
					row-gap: 4rem;
					padding: 2.4rem 0;

					.RowCol {
						width: 100%;
						height: 5.5rem;
						border-radius: 1.5rem;
						background: #fff;
						display: flex;
						align-items: center;
						gap: 4rem;
						padding-left: 4.1rem;

						.Left {
							width: 5.4rem;
							height: 5.4rem;
							border-radius: 50%;
							overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;

							svg {
								width: 2.9rem;
								height: 2.9rem;
							}
						}

						.Right {
							display: flex;
							flex-direction: column;
							gap: 0.2rem;

							h2 {
								color: #000;
								font-family: Poppins;
								font-size: 2.7rem;
								font-style: normal;
								font-weight: 700;
								line-height: 3.45rem; /* 127.273% */
							}

							p {
								color: rgba(60, 60, 67, 0.6);
								font-feature-settings: "clig" off, "liga" off;
								font-family: Poppins;
								font-size: 0.8rem;
								font-style: normal;
								font-weight: 400;
								line-height: 1.2rem; /* 150% */
							}
						}
					}
				}

				.Bottom {
					padding-right: 15rem;
					overflow: hidden;

					h5 {
						color: #000;
						font-family: Poppins;
						font-size: 1.6rem;
						font-style: normal;
						font-weight: 500;
						line-height: 2rem; /* 125% */
						letter-spacing: 0.01px;
						padding-left: 2rem;
					}
				}
			}
		}
	}
}
