.Container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.Container::-webkit-scrollbar {
	display: none;
}

/* Post start */
.PostIndex {
	display: flex;
	flex-direction: column;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54));
	border-radius: 0rem 4.7rem 0rem 0rem;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.PostIndex img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.PostIndexBtnContainer {
	width: 25.747rem;
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.PostIndexBtnContainer button {
	width: 25.747rem;
	height: 7.998rem;
	background: #ffffff;
	border: 0.1949rem solid #ffffff;
	margin-bottom: 1.7rem;
	font-weight: 400;
	font-size: 2.7286rem;
	line-height: 4.1rem;
	color: #000000;
	cursor: pointer;
}

.PostIndexBtnContainer button:last-child {
	background: transparent;
	color: #fff;
}

/* Post end */

/* EditPost Start */
.Post {
	display: flex;
	flex-direction: column;
}

.EditPostBody {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	gap: 3rem;
	/* padding-top: 9.6rem; */
	padding-top: 2.6rem;
}

.EditPostBodyLeft {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.EditPostBox {
	width: 67rem;
	height: 44.8rem;
	background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 35.25%);
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.ImageContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
	cursor: pointer;
	position: relative;

	img {
		width: 100%;
		height: 100%;
	}

	video {
		width: 100%;
		height: 100%;
	}

	h2 {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		position: absolute;
		font-size: 3rem;
		background: rgba(40, 40, 40, 0.1);
		backdrop-filter: blur(3px);
		padding: 2rem 5rem;
	}
}

.EditPostBox h1 {
	width: 100%;
	height: 7.312rem;
	background: #161616;
	font-style: normal;
	font-weight: 400;
	font-size: 2.65895rem;
	line-height: 3.2rem;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.LeftBottom {
	width: 67rem;
	height: 14.8rem;
	margin-top: 2rem;
}

/* EnablePollContainer Start */
.EnablePollContainer {
	width: 100%;
	height: 6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 1.5rem;
	padding-right: 2rem;
}

.EnablePollContainer .left {
	height: 100%;
}

.EnablePollContainer .left button {
	width: 7.881rem;
	height: 5.881rem;
	background: #909aff;
	border: 0.144056rem solid #919aff;
	font-style: normal;
	font-weight: 400;
	font-size: 2.01678rem;
	line-height: 3rem;
	color: #fafaff;
	margin-right: 0.9rem;
	cursor: pointer;
}

.EnablePollContainer .left button:first-child {
	width: 7.881rem;
	height: 5.881rem;
	background: transparent;
	color: #5766f9;
	border: 0.144056rem solid #919aff;
}

.EnablePollContainer .left button:hover {
	transition: all 0.3s ease-in-out;
	background: #5766f9;
	color: #fff;
}

.EnablePollContainer .right {
	height: 6rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.EnablePollContainer .right p {
	margin-right: 1.3rem;
	font-style: normal;
	font-weight: 400;
	font-size: 2.01678rem;
	line-height: 3rem;
	color: #000000;
}

/* EnablePollContainer End */

.ShowPollResult {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 2rem 0 1.5rem;
	margin-top: 1.5rem;
}

.ShowPollResult p {
	font-style: normal;
	font-weight: 400;
	font-size: 2.01678rem;
	line-height: 3rem;
	color: #000000;
}

.PollContainer {
	display: flex;
	flex-direction: column;
}

.PollContainer p {
	font-style: normal;
	font-weight: 500;
	font-size: 1.27045rem;
	line-height: 1.5rem;
	text-transform: uppercase;
	color: #000000;
}

.PollsCont {
	margin-top: 2.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-left: 0.5rem;
}

.PollsCont label {
	display: flex;
}

/* FIXME: */
.PollsCont label input {
	margin-left: 0.8rem;
	height: 1.397rem;
	background: #4a72fe;
	width: 30.745rem;
}

.PollsCont label p {
	font-style: normal;
	font-weight: 500;
	font-size: 1.27045rem;
	line-height: 1.5rem;
	text-transform: uppercase;
	color: #000000;
	margin-left: 0.7rem;
}

.EditPostBodyRight {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.RightInner {
	width: 43.335rem;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.SetTimerContainer p {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 1.36275rem;
	line-height: 1.6rem;
	text-transform: uppercase;
	color: #000000;
}

.SetTimerInner {
	padding: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.SetTimerInnerLeft {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1.363rem;
}

.dates {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4.425rem;
	height: 4.325rem;
	background: #e1e4ff;
	border-radius: 136.275rem;
	cursor: pointer;

	&.active {
		background: #909aff;
		color: #ffffff;
	}
}

.SetTimerInnerRight {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 1.36275rem;
	line-height: 1.6rem;
	text-transform: uppercase;
	color: #000000;

	select {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;
	}
}

.describeContainer {
	margin-top: 4.6rem;

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 1.36275rem;
		line-height: 1.6rem;
		text-transform: uppercase;
		color: #000000;
		margin-bottom: 2.6rem;
	}

	.TextAreaContainer {
		width: 100%;
		height: 9.812rem;
		overflow: hidden;
		border-radius: 0.681374rem;
		border: 0.136275rem solid rgba(40, 40, 40, 0.21);
		display: flex;
		flex-direction: column;
		position: relative;

		.textarea1 {
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
			background: #e7e9fe;
			resize: none;
			font-style: normal;
			font-weight: 400;
			font-size: 1.36275rem;
			line-height: 1.8rem;
			color: #000000;
			padding: 0.5rem 1.5rem;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.row2 {
			width: 100%;
			height: 35%;
			display: flex;
			// align-items: center;

			.textarea2 {
				width: 100%;
				height: 100%;
				border: none;
				outline: none;
				background: #e7e9fe;
				resize: none;
				font-style: normal;
				font-weight: 400;
				font-size: 1.36275rem;
				line-height: 1.8rem;
				color: #000000;
				padding: 0.5rem 1.5rem;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}
			}

			span {
				align-self: flex-end;
				width: 12%;
				padding-bottom: 1rem;
				padding-left: 1rem;
				text-align: bottom;
				display: block;
				color: #000;
				font-family: "Inter";
				font-size: 1rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}

.PostContainer {
	margin-top: 3.3rem;
}

.PostContainer p {
	font-style: normal;
	font-weight: 500;
	font-size: 1.36275rem;
	line-height: 1.6rem;
	text-transform: uppercase;
	color: #000000;
	margin-bottom: 1.2rem;
}

.PostCont1 {
	width: 100%;
	height: 16.276rem;
	background: #ffffff;
	border-radius: 0.726886rem;
	margin-bottom: 1.3rem;
	padding: 2rem 4.4rem 0 2.5rem;
}

.PostCont1 h2 {
	display: block;
	font-style: normal;
	font-weight: 500;
	font-size: 2.00693rem;
	line-height: 138.4%;
	color: #000000;
}

.PostCont1Inn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1.9rem;
}

.PostCont1Inn h5 {
	font-style: normal;
	font-weight: 500;
	font-size: 1.16302rem;
	line-height: 138.4%;
	color: #323232;
}

.PostCont1Inn p {
	font-style: normal;
	font-weight: 600;
	font-size: 1.01764rem;
	line-height: 138.4%;
	color: #3a0dbb;
	cursor: pointer;
}

.PostCont2 {
	width: 100%;
	height: 15.038rem;
	background: #ffffff;
	border-radius: 0.726886rem;
	padding: 2.1rem 4.6rem 0 2.7rem;
}

.PostCont2 p {
	font-style: normal;
	font-weight: 400;
	font-size: 0.872263rem;
	line-height: 138.4%;
	color: #959595;
}

.PostCont2Inn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.4rem;
}

.PostCont2Inn h2 {
	font-style: normal;
	font-weight: 500;
	font-size: 1.01764rem;
	line-height: 138.4%;
	color: #323232;
}

.uploadBtn {
	font-weight: 600;
	font-size: 1.90646rem;
	line-height: 2.9rem;
	color: #ffffff;
	width: 100%;
	height: 6.985rem;
	background: #1b47c1;
	border-radius: 0.68088rem;
	cursor: pointer;
	border: none;
	margin-top: 1.4rem;
	transition: all 0.2s ease-in-out;
	overflow: hidden;
	position: relative;

	&:hover {
		background: #002793;
	}

	&:disabled {
		background: #666666;
		cursor: default;
	}

	div {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		z-index: 2;
	}

	span {
		position: absolute;
		top: 0;
		left: 0%;
		height: 100%;
		width: 0%;
		background: rgba(0, 255, 55, 0.645);
		z-index: 1;
		transition: all .3s ease-in-out;
	}
}

/* EditPost End */
