.AdsApplyWrapper {
	width: calc(100% - 14.2rem);
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	background: rgba(153, 155, 167, 0.28);
	z-index: 20;
	display: flex;
	align-items: center;
	justify-content: center;
	// border: 3px solid #f00;
}

.AdsOfferWrapper {
	margin-top: -2rem;
	width: 135rem;
	height: 90%;
	background: #fff;
	overflow: hidden;
	position: relative;
	padding: 3rem 6rem 0rem 6rem;
	display: flex;
	flex-direction: column;

	.Top {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		h1 {
			width: 52rem;
			color: #0b2110;
			text-align: center;
			font-family: Poppins;
			font-size: 4rem;
			font-style: normal;
			font-weight: 500;
			line-height: 120%; /* 48px */
			letter-spacing: -0.032rem;

			span {
				color: #3a0dbb;
			}
		}

		button {
			position: absolute;
			top: 20%;
			right: 0;
			width: 3.5rem;
			height: 3.5rem;
			border: none;
			background: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.3s ease-in-out;
			border-radius: 1rem;

			&:hover {
				background: rgba(0, 0, 0, 0.3);
			}

			svg {
				width: 2.4rem;
				height: 2.4rem;

				path {
					stroke: #000;
				}
			}
		}
	}

	.Mid {
		margin-top: -2rem;
		width: 21rem;
		height: 5.1rem;
		margin-bottom: 1rem;
		display: flex;
		padding: 0.8rem;
		align-items: center;
		gap: 0.8rem;
		border-radius: 2.7rem;
		background: #fff;
		box-shadow: 0px 10px 15px 0px rgba(24, 59, 86, 0.1);

		button {
			height: 100%;
			padding: 0.4rem 1.6rem;
			border-radius: 1.6rem;
			border: none;
			color: #627065;
			text-align: center;
			font-family: Poppins;
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			&.Active {
				color: #fff;
				background: #3a0dbb;
			}
		}
	}

	.Bottom {
		flex: 1;
		overflow: hidden;
		display: grid;
		place-content: center;
		// grid-template-columns: repeat(3, 1fr);
		// column-gap: 3.7rem;
		padding-bottom: 2rem;

		.Box {
			border-radius: 2rem;
			background: #fff;
			box-shadow: 0px 10px 15px 0px rgba(24, 59, 86, 0.1);

			display: flex;
			flex-direction: column;
			padding: 2rem 3.5rem 1rem 3.3rem;
			overflow: hidden;

			h1 {
				color: #000;
				text-align: center;
				font-family: Poppins;
				font-size: 2.8rem;
				font-style: normal;
				font-weight: 700;
				line-height: 120%; /* 33.6px */
				margin-bottom: 1rem;
			}

			p {
				color: #959595;
				text-align: center;
				font-family: Poppins;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 18px */
				margin-bottom: 1rem;
				padding: 0 3rem;
			}

			img {
				width: 100%;
				height: 14rem;
				object-fit: contain;
				border-radius: 0.6rem;
				margin-bottom: 1rem;
			}

			.Amount {
				color: #000;
				text-align: center;
				font-family: Poppins;
				font-size: 4rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				margin-bottom: 2rem;

				span {
					font-size: 2rem;
					font-weight: 400;

					&:first-child {
						font-size: 2.3rem;
						font-weight: 500;
					}
				}
			}

			.List {
				margin-bottom: 3rem;

				ul {
					display: flex;
					flex-direction: column;
					gap: 1.6rem;

					li {
						color: #000;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 111.4%; /* 13.368px */
						text-transform: capitalize;
						list-style: none;
						display: flex;
						align-items: center;
						gap: 0.82rem;

						svg {
							width: 1.54rem;
							height: 1.54rem;
						}
					}
				}
			}

			.Wrapper2 {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				button {
					width: 24rem;
					height: 4.8rem;
					border-radius: 0.8rem;
					background: #3a0dbb;
					border: none;
					color: #fff;
					font-family: Poppins;
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-transform: capitalize;
					cursor: pointer;
				}
			}
		}
	}
}

.AdsFormWrapper {
	margin-top: -2rem;
	width: 135rem;
	height: 90%;
	background: #fff;
	overflow: hidden;
	position: relative;
	border-radius: 3rem;
	padding: 3rem 6rem 3rem 6rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.Top {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		position: relative;

		h2 {
			color: #0b2110;
			font-family: Poppins;
			font-size: 3.5rem;
			font-style: normal;
			font-weight: 500;
			line-height: 120%; /* 48px */
			letter-spacing: -0.032rem;
		}

		p {
			width: 27rem;
			color: #282828;
			font-family: Poppins;
			font-size: 0.8rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		button {
			position: absolute;
			top: 20%;
			right: 0;
			width: 3.5rem;
			height: 3.5rem;
			border: none;
			background: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.3s ease-in-out;
			border-radius: 1rem;

			&:hover {
				background: rgba(0, 0, 0, 0.3);
			}

			svg {
				width: 2.4rem;
				height: 2.4rem;

				path {
					stroke: #000;
				}
			}
		}
	}

	.Bottom {
		flex: 1;
		display: flex;
		gap: 5.8rem;

		.Left {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.8rem;
		}

		.Right {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.8rem;

			.Wrapper {
				display: flex;
				flex-direction: column;

				label {
					color: #333;
					font-family: Poppins;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-bottom: 0.3rem;
				}

				span {
					color: #959595;
					font-family: Poppins;
					font-size: 1rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					margin-bottom: 2.4rem;
				}

				.box {
					width: 100%;
					height: 18rem;
					border-radius: 1.4rem;
					border: 1.764px dashed #878a8f;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					h4 {
						color: #2d3748;
						font-family: Poppins;
						font-size: 2rem;
						font-style: normal;
						font-weight: 500;
						line-height: 140%; /* 33.6px */
						margin-bottom: 1.1rem;

						span {
							color: #3a0dbb;
							font-family: Poppins;
							font-size: 2.4rem;
							font-style: normal;
							font-weight: 500;
							line-height: 140%;
						}
					}

					h5 {
						color: #959595;
						text-align: center;
						font-family: Poppins;
						font-size: 1.1rem;
						font-style: normal;
						font-weight: 400;
						line-height: 138.4%; /* 16.608px */
					}
				}
			}

			button {
				margin-top: 4rem;
				width: 100%;
				height: 6rem;
				border-radius: 1rem;
				background: #3a0dbb;
				border: none;
				color: #fff;
				font-family: Poppins;
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 500;
				line-height: 138.4%; /* 22.144px */
				cursor: pointer;
			}
		}

		.InputWrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 0.4rem;

			label {
				color: #333;
				font-family: Poppins;
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

				span {
					color: #959595;
					font-family: Poppins;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
			span {
				color: #959595;
				font-family: Poppins;
				font-size: 1rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			.Input {
				width: 100%;
				height: 4rem;
				background: #eee;
				padding: 0 2.2rem;

				&.textarea {
					height: 9rem;
				}

				input {
					width: 100%;
					height: 100%;
					background: transparent;
					border: none;
					outline: none;
					font-size: 1.7rem;
				}

				textarea {
					width: 100%;
					height: 100%;
					background: transparent;
					border: none;
					outline: none;
					font-size: 1.7rem;
					resize: none;
					padding: 0.5rem 0;
				}
			}
		}

		.InputWrapperRow {
			display: flex;
			gap: 2.3rem;
		}
	}
}

.AdsPaymentWrapper {
	margin-top: -2rem;
	width: 114rem;
	height: 69rem;
	background: #fff;
	overflow: hidden;
	position: relative;
	border-radius: 3rem;
	padding: 4.5rem 6rem 6rem 6rem;
	display: flex;
	gap: 1rem;

	.Left {
		width: 38rem;
		padding: 1rem 0;

		.Box {
			border-radius: 2rem;
			background: #fff;
			box-shadow: 0px 10px 15px 0px rgba(24, 59, 86, 0.1);

			display: flex;
			flex-direction: column;
			padding: 2.1rem 3.5rem 2.6rem 3.3rem;
			overflow: hidden;

			h1 {
				color: #000;
				text-align: center;
				font-family: Poppins;
				font-size: 2.8rem;
				font-style: normal;
				font-weight: 700;
				line-height: 120%; /* 33.6px */
				margin-bottom: 1.8rem;
			}

			p {
				color: #959595;
				text-align: center;
				font-family: Poppins;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 18px */
				margin-bottom: 1rem;
				padding: 0 3rem;
			}

			img {
				width: 100%;
				height: 14rem;
				object-fit: contain;
				border-radius: 0.6rem;
				margin-bottom: 3rem;
			}

			.Amount {
				color: #000;
				text-align: center;
				font-family: Poppins;
				font-size: 4rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				margin-bottom: 3rem;

				span {
					font-size: 2rem;
					font-weight: 400;

					&:first-child {
						font-size: 2.3rem;
						font-weight: 500;
					}
				}
			}

			.List {
				margin-bottom: 4rem;

				ul {
					display: flex;
					flex-direction: column;
					gap: 1.6rem;

					li {
						color: #000;
						font-family: Poppins;
						font-size: 1.2rem;
						font-style: normal;
						font-weight: 400;
						line-height: 111.4%; /* 13.368px */
						text-transform: capitalize;
						list-style: none;
						display: flex;
						align-items: center;
						gap: 0.82rem;

						svg {
							width: 1.54rem;
							height: 1.54rem;
						}
					}
				}
			}

			.Wrapper2 {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				button {
					width: 24rem;
					height: 4.8rem;
					border-radius: 0.8rem;
					background: #3a0dbb;
					border: none;
					color: #fff;
					font-family: Poppins;
					font-size: 1.4rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-transform: capitalize;
					cursor: pointer;
				}
			}
		}
	}

	.Right {
		flex: 1;
		position: relative;

		.BackBtn {
			position: absolute;
			top: 1rem;
			right: 0;
			width: 3.5rem;
			height: 3.5rem;
			border: none;
			background: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.3s ease-in-out;
			border-radius: 1rem;

			&:hover {
				background: rgba(0, 0, 0, 0.3);
			}

			svg {
				width: 2.4rem;
				height: 2.4rem;

				path {
					stroke: #000;
				}
			}
		}

		.Wrapper {
			padding-left: 4rem;

			h5 {
				color: #333;
				font-family: Poppins;
				font-size: 2.4rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 1rem;
			}

			h6 {
				color: #959595;
				font-family: Inter;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-bottom: 0.5rem;
			}

			h1 {
				color: #333;
				font-family: Inter;
				font-size: 5.13rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 3.4rem;
			}

			h4 {
				color: #000000;
				font-family: Poppins;
				font-size: 1.8rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 2.4rem;
				display: flex;
				align-items: center;
				gap: 4.6rem;

				svg {
					width: 5.7rem;
					height: 4.7rem;
				}
			}

			.CartDetails {
				margin-bottom: 3.8rem;
				width: 100%;
				display: flex;
				align-items: center;
				gap: 4rem;

				input {
					border: none;
					border-bottom: 1px solid #000;
					padding-bottom: 1rem;
					outline: none;

					&:nth-child(1) {
						height: 100%;
					}

					&:nth-child(2) {
						width: 7rem;
						text-align: center;
					}

					&:nth-child(3) {
						width: 7rem;
						text-align: center;
					}
				}
			}

			.Option {
				display: flex;
				flex-direction: column;
				margin-bottom: 3.2rem;

				h2 {
					color: #000;
					font-family: Poppins;
					font-size: 1.8rem;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					margin-bottom: 1.6rem;
				}

				.Radio {
					width: 30rem;
					display: flex;
					flex-wrap: wrap;
					column-gap: 2.4rem;
					row-gap: 1.6rem;

					div {
						display: flex;
						align-items: center;
						gap: 0.9rem;

						label {
							color: #000;
							font-family: Poppins;
							font-size: 1.4rem;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
					}
				}
			}

			.BottomPart {
				position: relative;

				h3 {
					color: #000;
					font-family: Inter;
					font-size: 2.4rem;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}

				.PayBtn {
					position: absolute;
					right: 5rem;
					top: 0;
					width: 18.5rem;
					height: 6.9rem;
					border-radius: 1.05rem;
					background: #3a0dbb;
					color: #fff;
					font-family: Poppins;
					font-size: 1.6rem;
					font-style: normal;
					font-weight: 500;
					line-height: 138.4%; /* 22.144px */
					cursor: pointer;
					border: none;
					transition: all 0.3s ease-in-out;

					&:hover {
						background: #230085;
					}
				}
			}
		}

		.Success {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			svg {
				width: 9.8rem;
				height: 9.8rem;
				margin-bottom: 5rem;
			}

			h2 {
				color: #000;
				font-family: Poppins;
				font-size: 2.4rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 1.7rem;
			}

			p {
				width: 25rem;
				color: #959595;
				text-align: center;
				font-family: Poppins;
				font-size: 1.2rem;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 18px */
			}
		}
	}
}
