.SupportWrapper {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;
	display: flex;
	gap: 1rem;
	padding: 1rem 1rem 0 3rem;
	position: relative;

	&::-webkit-scrollbar {
		display: none;
	}

	.Left {
		width: 100%;
		height: 100%;
		display: flex;
		gap: 1rem;
		padding-bottom: 2rem;
		flex-direction: column;

		.Top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 3rem;

			h1 {
				color: #000;
				font-family: Poppins;
				font-size: 4.2rem;
				font-style: normal;
				font-weight: 600;
				line-height: 138.4%; /* 58.128px */
			}

			button {
				width: 10rem;
				height: 4.5rem;
				border: none;
				cursor: pointer;
				border-radius: 1rem;
				background: #471cf1;
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 1.8rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: #2f149c;
					transform: scale(1.05);
				}
			}
		}

		.Middle {
			flex: 1;
			padding: 0 2rem;
			overflow: hidden;
			margin-bottom: 4rem;
			overflow-y: scroll;
			cursor: pointer;

			&::-webkit-scrollbar {
				width: 5px;
			}

			&::-webkit-scrollbar-thumb {
				background: #471cf1;
				border-radius: 1rem;
			}

			.Loading{
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			p {
				color: #666;
				font-family: Poppins;
				font-size: 1.4rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 1rem;
				margin-bottom: 2rem;
			}

			.Box {
				width: 100%;
				margin-bottom: 1.5rem;
				border-radius: 1.5rem;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1.5rem 3rem 1.5rem 2rem;
				gap: 2rem;

				.BoxLeft {
					// min-height: 10rem;
					width: 100%;

					h6 {
						color: #000;
						font-family: Poppins;
						font-size: 1rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					h3 {
						color: #000;
						font-family: Poppins;
						font-size: 1.8rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
					span {
						color: #666;
						text-align: center;
						font-family: Poppins;
						font-size: 1rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}

				.BoxRight {
					color: #aeaeae;
					text-align: center;
					font-family: Poppins;
					font-size: 1rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}

		.Bottom {
			display: grid;
			place-items: center;

			button {
				width: 80%;
				height: 7rem;
				background: #471cf1;
				border: none;
				color: #fff;
				cursor: pointer;
				border-radius: 1rem;
				font-size: 2.5rem;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: #2f149c;
					transform: scale(1.05);
				}
			}
		}
	}

	.Right {
		min-width: 0px;
		max-width: 0px;
		height: 100%;
		background: #fff;
		border-radius: 0 2rem 2rem 0;
		transition: all 0.3s ease-in-out;
		opacity: 0;
		// position: absolute;
		right: 0;
		top: 0;

		&.ActiveRight {
			min-width: 500px;
			max-width: 500px;
			height: 100%;
			opacity: 1;
		}
	}
}

.TitlePopupWrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	display: grid;
	place-items: center;

	.TitlePopup {
		width: 40rem;
		height: 20rem;
		background: #eee;
		border-radius: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2rem;

		input {
			width: 90%;
			height: 7rem;
			outline: none;
			border-radius: 1rem;
			border: none;
			background: #fff;
			padding: 2rem;
			font-size: 2.5rem;
		}

		button {
			width: 80%;
			height: 7rem;
			background: #471cf1;
			border: none;
			color: #fff;
			cursor: pointer;
			border-radius: 1rem;
			font-size: 2.5rem;
			transition: all 0.3s ease-in-out;

			&:hover {
				background: #2f149c;
				transform: scale(1.05);
			}
		}
	}
}

.PopupWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.Top {
		width: 100%;
		padding: 1rem;

		div {
			display: flex;
			align-items: center;

			button {
				width: 3rem;
				height: 3rem;
				border: none;
				background: transparent;
				cursor: pointer;
				font-size: 3rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			h1 {
				color: #000;
				font-family: Poppins;
				font-size: 2.6rem;
				font-style: normal;
				font-weight: 600;
				line-height: 138.4%; /* 35.984px */
			}
		}

		p {
			padding-top: 1rem;
			padding-left: 3rem;
			margin-bottom: 0;
			color: #454545;
			font-family: Poppins;
			font-size: 1.4rem;
			font-style: italic;
			font-weight: 400;
			line-height: 138.4%; /* 19.376px */
		}
	}

	.Bottom {
		flex: 1;

		.EmailWrapper {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 1.3rem;
			align-items: flex-end;
			justify-content: center;
			padding: 0 5rem;

			input {
				width: 100%;
				height: 8rem;
				padding: 3.5rem;
				border-radius: 12rem;
				border: 1.2px solid #666;
			}

			textarea {
				width: 100%;
				height: 29rem;
				padding: 3.5rem;
				resize: none;
				border-radius: 2.5rem;
				border: 1.2px solid #666;
			}

			button {
				border: none;
				background: #471cf1;
				border-radius: 1.2rem;
				height: 6.6rem;
				width: 22rem;
				color: #fff;
				text-align: center;
				font-family: Poppins;
				font-size: 1.8rem;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: 3rem;
				transition: all 0.3s ease-in-out;
				cursor: pointer;

				&:hover {
					background: #2200ac;
				}
			}
		}

		.ChatWrapper {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding: 0 4rem;

			.Box {
				width: 100%;
				margin-bottom: 1.5rem;
				border-radius: 1.5rem;
				background: #f1f1f1;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 1.5rem 3rem 1.5rem 2rem;
				gap: 2rem;

				.BoxLeft {
					// min-height: 10rem;
					width: 100%;

					h6 {
						color: #000;
						font-family: Poppins;
						font-size: 1rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					h3 {
						color: #000;
						font-family: Poppins;
						font-size: 1.8rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
					span {
						color: #666;
						text-align: center;
						font-family: Poppins;
						font-size: 1rem;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}

				.BoxRight {
					color: #aeaeae;
					text-align: center;
					font-family: Poppins;
					font-size: 1rem;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}

			.MessageWrapper {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				padding-bottom: 8rem;

				.MessageContentWrapper {
					flex: 1;

					.MeWrapper {
						display: flex;
						justify-content: flex-end;
						margin-bottom: 1rem;

						.Me {
							color: #fff;
							font-family: Poppins;
							font-size: 1.69rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							border-radius: 18.165px 18.165px 0px 18.165px;
							background: #471cf1;
							min-width: 22rem;
							min-height: 8.7rem;
                            padding: 1rem;
						}
					}
					.YouWrapper {
						display: flex;
						justify-content: flex-start;
						margin-bottom: 1rem;

						.You {
							color: #000;
							font-family: Poppins;
							font-size: 1.69rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							border-radius: 18.165px 18.165px 0px 18.165px;
							background: #e7e9fe;
							min-width: 22rem;
							min-height: 8.7rem;
                            padding: 1rem;
						}
					}
				}

				.InputWrapper {
					width: 100%;
					height: 7.5rem;
					border-radius: 12rem;
					border: 1px solid #a6a6a6;
					display: flex;
					align-items: center;
					padding-right: 1rem;
					overflow: hidden;

					input {
						flex: 1;
						height: 100%;
						border: none;
						outline: none;
						padding: 0 2rem;
						font-size: 1.7rem;
					}

					button {
						width: 4.5rem;
						height: 4.5rem;
						background: #471cf1;
						border-radius: 50%;
						border: none;
						display: grid;
						place-items: center;
						cursor: pointer;
						transition: all 0.3s ease-in-out;

						&:hover {
							background: #2600be;
						}
					}
				}
			}
		}
	}
}
