.InfoButtonContainer {
	position: relative;
	width: max-content;
	height: max-content;
	// position: absolute;
	// top: 7rem;
	// right: 2rem;
	z-index: 1000;
	@media screen and (max-width: 1000px) {
		display: none;
	}
}

.InfoButton {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #1e1e1e;
	border: 1px solid #00a498;
	cursor: pointer;
	padding: 0.5rem;
	border-radius: 0.5rem;

	@media screen and (max-width: 1000px) {
		display: none;
	}

	@media screen and (max-width: 800px) {
		top: 5rem;
	}

	svg {
		width: 100%;
		height: 100%;
	}
}

.Tooltip {
	position: absolute;
	top: 6rem;
	right: -2rem;
	background: #1e1e1e;
	box-shadow: 0px 10px 7px 10px solid #00a498;
	padding: 2rem;
	padding-top: 6rem;
	border-radius: 4px;
	z-index: 1000;
	width: 33vw;
	color: #fff;
	font-family: "Poppins";
	font-size: 1.3rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	display: flex;
	flex-direction: column;
	gap: 1rem;
	z-index: 1;

	opacity: 0;
	transform: scale(0);
	transition: all ease-in-out 0.3s;
	transform-origin: 92% 0%;

	&.Active {
		opacity: 1;
		transform: scale(1);
	}

	&::before {
		content: "";
		height: 4rem;
		width: 6rem;
		transform: rotate(137deg);
		background: #1e1e1e;
		position: absolute;
		right: 1.5rem;
		top: -0.2rem;
		z-index: -1;
	}

	.Cross {
		position: absolute;
		right: 2rem;
		top: 2rem;
		cursor: pointer;
		transition: all ease-in-out 0.3s;
		&:hover {
			svg {
				path {
					fill: #00a498;
				}
			}
		}
	}

	a {
		color: #00a498;
	}
}
