.CustomPollWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.8);
	z-index: 20;

	.CustomPoll {
		width: 70rem;
		height: 60rem;
		border-radius: 1rem 1rem 2.2rem 2rem;
		background: rgba(48, 48, 48, 0.51);
		backdrop-filter: blur(8.71966552734375px);
		padding: 8.3rem 10rem 4.8rem 8.1rem;
		display: flex;
		flex-direction: column;
		gap: 3.4rem;

		.Top {
			h1 {
				color: #fff;
				font-family: "Poppins";
				font-size: 3.14rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 38.76px */
				margin-bottom: 2.7rem;
			}

			p {
				color: #fff;
				font-family: "Poppins";
				font-size: 1.6rem;
				font-style: normal;
				font-weight: 400;
				line-height: 123.4%; /* 19.744px */
			}
		}

		.Middle {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			gap: 1.9rem;

			input {
				width: 23rem;
				height: 8.4rem;
				border-radius: 1.74rem;
				background: rgba(255, 255, 255, 0.1);
				backdrop-filter: blur(8.71966552734375px);
				text-align: center;
				border: none;
				color: #fff;
				font-family: "Poppins";
				font-size: 1.74rem;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				outline: none;

				&::placeholder {
					color: #ffffffa6;
					font-family: "Poppins";
					font-size: 1.74rem;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}

			button {
				width: 23rem;
				height: 8.4rem;
				border-radius: 1.74rem;
				background: rgba(255, 255, 255, 0.1);
				backdrop-filter: blur(8.71966552734375px);
				text-align: center;
				border: none;
				color: #fff;
				font-family: "Poppins";
				font-size: 1.74rem;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				cursor: pointer;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: rgba(52, 60, 213, 0.5);
				}
			}
		}

		.Bottom {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1.6rem;

			button {
				border: none;
				width: 23rem;
				height: 8.4rem;
				border-radius: 1.7rem;
				color: #fff;
				font-family: "Poppins";
				font-size: 1.74rem;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				cursor: pointer;

				&:first-child {
					background: rgba(27, 71, 193, 0.16);
					backdrop-filter: blur(8.71966552734375px);
				}

				&:last-child {
					background: #1b47c1;
					backdrop-filter: blur(8.71966552734375px);
				}
			}
		}
	}
}