.ChangePassword {
	background-color: #e7e9fe;
	height: 100vh;
	width: 100vw;
	padding: 10vh 8vw;

	.container {
		display: flex;
		flex-direction: column;
		gap: 4rem;
		.header {
			display: flex;
			justify-content: space-between;
			position: relative;
			margin-bottom: 10px;
			.textContainer {
				padding-left: 10px;
				padding-top: 2.5rem;
				display: flex;
				flex-direction: column;
				gap: 2vh;

				.heading {
					font-weight: 500;
					font-size: 4rem;
					line-height: 5rem;
					color: #000;
				}

				.text {
					font-size: 2rem;
					color: gray;
				}
			}
		}
		.passwordWrapper {
			display: flex;
			flex-direction: column;
			gap: 3rem;

			& > div {
				flex: 1;
			}

			.newPassword {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				.lableContainer {
					.label {
						margin-left: 10px;
						font-weight: 400;
						font-size: 2.5rem;
					}
				}

				.input {
					.inputPass {
						position: relative;

						.inputBox {
							padding: 2.4rem 1.6rem;
							border: 1px solid #afb6fd;
							background: transparent;
							outline: none;
							border-radius: 10px;
							width: 100%;
						}

						span {
							position: absolute;
							top: 50%;
							right: 2rem;
							transform: translateY(-50%);
							cursor: pointer;
						}
					}
				}
				.strengthBar {
					display: flex;
					gap: 1rem;
					.bar {
						height: 5px;
						width: 20%;
						background-color: #afb6fd;
						border-radius: 10px;
					}
					.bar1 {
						background-color: #ff0000;
					}
					.bar2 {
						background-color: #ff7f00;
					}
					.bar3 {
						background-color: #ffff00;
					}
					.bar4 {
						background-color: #7fff00;
					}
					.bar5 {
						background-color: #00ff00;
					}
				}
			}

			.confirmPassword {
				height: 500px;
			}

			ul {
				list-style: none;
				li {
					img {
						width: 15px;
						height: 15px;
					}
					display: flex;
					gap: 1rem;
                    align-items: center;
					margin-left: 10px;
					font-weight: 300;
					font-size: 2.2rem;
				}
			}
		}
		.submitButtonContainer {
            margin-top: 10px;
			display: flex;
			justify-content: center;

			.signInButton {
				background-color: white;
				color: black;
				width: 100%;
				padding: 2rem 0;
				border-radius: 60px;
				border: none;
				box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.25);
			}

			.activeButton {
				background: var(--gradient1);
				color: white;
			}

			.submitButton {
				width: 100%;
				border-radius: 12px;
				padding: 2.5rem 5.5rem;
			}
		}
	}
}
