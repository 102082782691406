.Suggestion {
	width: 100%;
	// height: 35rem;
	margin-bottom: 2.1rem;
	display: flex;
	flex-direction: column;
	gap: 0.9rem;
	overflow: hidden;

	.SuggestionText {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h1 {
			overflow: hidden;
			color: #959595;
			font-family: Poppins;
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		a {
			color: #959595;
			text-decoration: none;
			font-family: Poppins;
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.OuterWrapper {
		width: 100%;
		height: 100%;

		.BoxWrapperMain {
			user-select: none;

			.BoxWrapper {
				// width: 26.8rem;
				width: 97%;
				height: 30.2rem;
				border-radius: 1.8rem;
				background: #fff;
				display: flex;
				flex-direction: column;
				overflow: hidden;

				.Top {
					width: 100%;
					height: 17.4rem;
					overflow: hidden;
					border-radius: 0 0 3.1rem 3.1rem;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.Bottom {
					flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

					.TopSection {
                        width: 100%;
						display: flex;
						gap: 0.6rem;
						padding: 0.7rem 1.3rem 0;
						margin-bottom: 2.4rem;

						.ImageWrapper {
							min-width: 9.5rem;
							max-width: 9.5rem;
							height: 9.5rem;
							overflow: hidden;
							border-radius: 50%;
							border: 2.7px solid #fff;
							margin-top: -5rem;
							background: #ffffff;

							img {
								width: 100%;
								height: 100%;
							}
						}

						.TextWrapper {
							display: flex;
							flex-direction: column;

							h2 {
								color: #000;
								text-align: center;
								font-family: Poppins;
								font-size: 1.4rem;
								font-style: normal;
								font-weight: 500;
								line-height: 123.4%; /* 17.786px */
							}
							p {
								color: #959595;
								text-align: center;
								font-family: Poppins;
								font-size: 0.8rem;
								font-style: normal;
								font-weight: 400;
								line-height: 123.4%; /* 9.913px */
							}
						}
					}

					.Button {
						width: 136.93px;
						padding: 6.895px 10.342px;
						border-radius: 9rem;
						background: #1b47c1;
						border: none;
						cursor: pointer;
						transition: all 0.3s ease-in-out;
						color: #fff;
						font-family: Poppins;
						font-size: 1.08rem;
						font-style: normal;
						font-weight: 500;
						line-height: 17.237px; /* 159.45% */
						letter-spacing: -0.065px;

						&:hover {
							background: #002da8;
						}
					}
				}
			}
		}
	}
}
