.Testing {
	padding: 10rem 30rem;
    background: #8d8d8d;

    .Wrap{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 2rem;
    }

	.Box {
		z-index: 1;
		position: relative;
		transition: all .8s ease-in-out;
        overflow: hidden;
        background: #fff;
        text-align: justify;
        padding: 0 3rem;

		&.ac {
            height: 10rem;
            width: 100%;
		}
        
		&.cl {
            width: 97%;
            height: 0rem;
            box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.1);
			&:nth-child(even) {
				transform: skewX(40deg);
			}
			&:nth-child(odd) {
				transform: skewX(-40deg);
			}
		}
	}
	.Top {
        width: 100%;
		height: 10rem;
		z-index: 2;
		background: #fff;
		transform: skewX(0deg);
		position: relative;
	}
	.Bottom {
        width: 100%;
		position: relative;
		height: 10rem;
		background: #fff;
		z-index: 2;
		transform: skewX(0deg);
	}
}
